<template>
  <div>
    <v-simple-table
        class="simple-table-border"
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Orden</th>
          <th class="text-left">Vendedores</th>
          <th class="text-left">Agencia</th>
          <th class="text-left">Operadores/Chóferes</th>
          <th class="text-left">Número de ventas</th>
          <th v-if="isOriginView('checkout-view')" class="text-left">Estatus visita</th>
          <th v-if="isOriginView('pay-guide-visit')" class="text-left">Estatus de pago</th>
          <th class="text-left">Fecha y hora de ingreso</th>
          <th class="text-left">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="(itemsVisitsInside) && itemsVisitsInside.length > 0">
          <tr v-for="(itemVisit, indexVisit) in itemsVisitsInside">
            <td>{{ itemVisit.arrival_order_number }}</td>
            <td>
              <v-simple-table>
                <thead>
                <tr>
                  <th>Color</th>
                  <th>Nombre</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="itemSalesUserRegisterVisit in itemVisit.sales_users_register_visit">
                  <td class="">
                    <template v-if="registerVisitHasColorAssigned(itemSalesUserRegisterVisit)">
                      <template>
                        <div
                            :style="{
                              backgroundColor: itemSalesUserRegisterVisit.color.hexadecimal_value,
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              border: '1px solid #000000',
                              marginRight: '8px'  // Espacio entre el círculo y el texto
                            }"
                        ></div>
                      </template>
                    </template>
                    <template v-else>
                      {{itemSalesUserRegisterVisit.manual_color_text}}
                    </template>

                  </td>
                  <td class="">
                    {{itemSalesUserRegisterVisit.sale_user.sale_user_fullname}}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </td>

            <td>
              <p class="ma-0 pa-0 font-weight-bold">{{ itemVisit.guide.guide_name }}</p>
              <a href="#!">{{ itemVisit.guide.agency.agency_business_name }}</a>
            </td>

            <td>
              <v-simple-table>
                <tbody>
                <tr>
                  <td>Respuesta: </td>
                  <th>{{itemVisit.come_with_driver_string}}</th>
                </tr>
                <template v-if="itemVisit.number_of_drivers && parseInt(itemVisit.number_of_drivers) > 0">
                  <tr>
                    <td>Cantidad: </td>
                    <th>{{itemVisit.number_of_drivers}}</th>
                  </tr>
                  <template v-if="itemVisit.driver_details && itemVisit.driver_details.length > 0">
                    <tr>
                      <td>Detalles: </td>
                      <th>
                        <v-simple-table>
                          <tr v-for="itemD in itemVisit.driver_details">
                            <td class="pt-2 pb-2">{{ itemD.driver_fullname }}</td>
                          </tr>
                        </v-simple-table>
                      </th>
                    </tr>
                  </template>
                </template>
                </tbody>
              </v-simple-table>
            </td>

            <td>
              {{ itemVisit.invoice_register_visit.length }}
            </td>

            <template v-if="isOriginView('pay-guide-visit')">
              <td>
                <v-chip
                    v-if="(itemVisit.guide_pay_visit)"
                    class="ma-0"
                    color="green"
                    text-color="white"
                >
                  Pagado
                </v-chip>
                <v-chip
                    v-else
                    class="ma-0"
                >
                  No pagado
                </v-chip>
              </td>
            </template>
            <template v-if="isOriginView('checkout-view')">
              <td>
                {{ itemVisit.status_visit_string }}
              </td>
            </template>

            <td>
              {{ formatFriendlyDate(itemVisit.created_at) }}
            </td>
            <td>
              <template v-if="isOriginView('pay-guide-visit')">
              <p v-if="itemVisit.invoice_register_visit.length <= 0" class="ma-0 pa-0 orange--text">
                <small><v-icon class="orange--text">mdi-alert-outline</v-icon> Esta visita no ha generado ninguna venta</small>
              </p>
              </template>
              <v-btn
                  @click="selectItemAction(itemVisit)" small color="black" outlined
                  class="black--text">
                Seleccionar
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="10" class="text-center">
              No se encontraron visitas
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: 'SearchVisitTable',
  props: ['itemsVisitsInside', 'originAction'],
  methods: {
    registerVisitHasColorAssigned(itemSalesUserRegisterVisit) {
      return itemSalesUserRegisterVisit.manual_color_text && itemSalesUserRegisterVisit.manual_color_text.trim().length <= 0
    },
    formatFriendlyDate(dateString) {
      // Parse the date string with moment
      return moment(dateString)
          .tz('America/Mexico_City') // Cambia esta zona horaria por la que desees
          .format('MMMM D, YYYY HH:mm'); // Formato: "Septiembre 26, 2024 17:23"
    },
    selectItemAction(item) {
      this.$emit('select-item-action', item)
    },
    isOriginView(originText) {
      return originText === this.originAction
    }
  }
}
</script>

<style scoped>
</style>