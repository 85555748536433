<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5 ">
          Administrador de colores
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para administrar agencias y guías</p>
      </v-card-subtitle>
      <v-card-text>
        <v-alert color="black" outlined dismissible>
          <v-icon>mdi-information-variant-circle-outline</v-icon>
          Por favor administre los clientes y sus respectivas comisiones para que sean calculadas en la caja.
        </v-alert>

        <v-btn @click="openColorsModal()" color="blue" class="mb-3 mr-3" outlined>Agregar color nuevo</v-btn>

        <v-data-table :loading="loadingColorsTable" :items="itemsActiveColors" :headers="headersColors">
          <template v-slot:item.actions="{item, index}">
            <v-btn icon @click="deleteColorItem(item, index)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn icon @click="showModalEditColor(item, index)">
              <v-icon>mdi-text-box-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>

    <v-dialog persistent max-width="950px" v-model="dialogEditColor">
      <v-card>
        <v-btn icon @click="dialogEditColor = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Modificar color
        </v-card-title>
        <v-card-text>
          <v-text-field label="Nombre del color" v-model="formColor.color_name"></v-text-field>
          <input type="color" v-model="formColor.hexadecimal_value" style="width: 100px; height: 40px; border: none;" />
          <v-text-field
              :disabled="true"
              label="Identificador del color"
              v-model="formColor.hexadecimal_value"
              prepend-icon="mdi-palette"
              maxlength="7"
          ></v-text-field>
          <v-btn
              :disabled="loadingSavingColor"
              @click="updateColor()"
              x-large
              color="black"
              class="white--text">
            Guardar cambios
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEditColor = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="950px" v-model="dialogAddColors">
      <v-card>
        <v-btn icon @click="dialogAddColors = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Agregar nuevo color
        </v-card-title>
        <v-card-text>
          <v-text-field label="Nombre del color" v-model="formColor.color_name"></v-text-field>
          <input type="color" v-model="formColor.hexadecimal_value" style="width: 100px; height: 40px; border: none;" />
          <v-text-field
              :disabled="true"
              label="Identificador del color"
              v-model="formColor.hexadecimal_value"
              prepend-icon="mdi-palette"
              maxlength="7"
          ></v-text-field>
          <v-btn
              :disabled="loadingSavingColor"
              @click="storeColor()"
              x-large
              color="black"
              class="white--text">
            Guardar nuevo color
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogAddColors = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'CustomersHome',
  data() {
    return {
      dialogAddColors: false,
      loadingColorsTable: false,
      headersColors: [
        {
          value: 'id',
          text: '#'
        },
        {
          value: 'color_name',
          text: 'Color'
        },
        {
          value: 'hexadecimal_value',
          text: 'Hexadecimal'
        },
        {
          value: 'actions',
          text: 'Acciones'
        }
      ],
      itemsActiveColors: [],
      loadingAddColor: false,

      dialogEditColor: false,
      currentItemColor: null,
      formColor: {
        color_name: '',
        hexadecimal_value: '',
      },
      loadingSavingColor: false,
    }
  },
  mounted() {
    this.loadColorsList()
  },
  methods: {
    updateColor(){
      if(
          this.formColor.color_name.trim()<=0||
          this.formColor.hexadecimal_value.trim()<=0
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      this.loadingSavingColor = true
      this.$http.put(`${this.$apiUrl}/color-register-visits/${this.currentItemColor.id}`, {
        '_method': 'PUT',
        color_name: this.formColor.color_name,
        hexadecimal_value: this.formColor.hexadecimal_value
      })
          .then((res) => {
            this.loadColorsList()
            this.dialogEditColor = false
            this.loadingSavingColor = false
          })
    },
    deleteColorItem(item){
      let confirmDelete = confirm('¿Está seguro de eliminar este registro?')
      if(confirmDelete){
        this.$http.delete(`${this.$apiUrl}/color-register-visits/${item.id}`).then((res) => {
          this.loadColorsList()
        }).finally(() => {
        })
      }
    },
    showModalEditColor(item){
      let localItemColor = item
      this.currentItemColor = localItemColor

      this.formColor.color_name = localItemColor.color_name
      this.formColor.hexadecimal_value = localItemColor.hexadecimal_value

      this.dialogEditColor = true
    },
    loadColorsList() {
      this.formColor.color_name = ''
      this.formColor.hexadecimal_value = ''

      this.loadingColorsTable = true
      this.getActiveColors()
          .then((res) => {
            this.itemsActiveColors = res.data.data
          }).finally(() => {
        this.loadingColorsTable = false
      })
    },
    getActiveColors() {
      return this.$http.get(`${this.$apiUrl}/color-register-visits?from-admin=1`)
    },
    openColorsModal() {
      this.dialogAddColors = true
      this.loadingSavingColor = false
      this.formColor.color_name = ''
      this.formColor.hexadecimal_value = ''
    },
    storeColor(){
      if(
          this.formColor.color_name.trim()<=0||
          this.formColor.hexadecimal_value.trim()<=0
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      this.loadingSavingColor = true
      this.$http.post(`${this.$apiUrl}/color-register-visits`, {
        color_name: this.formColor.color_name,
        hexadecimal_value: this.formColor.hexadecimal_value
      }).then((res) => {
        this.loadColorsList()
        this.dialogAddColors = false
        this.loadingSavingColor = false
          })
    }
  },

}
</script>