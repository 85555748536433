<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5">Testing</p>
        <hr />
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para testing</p>
      </v-card-subtitle>
      <v-card-text>
        <h2>Modo de conexión</h2>
        <v-btn color="blue" class="mr-2" @click="changeMode('online')">Cambiar a Online</v-btn>
        <v-btn color="green" class="mr-2" @click="changeMode('offline')">Cambiar a Offline</v-btn>
        <p>Estado actual de la API (Laravel): {{ connectionState.currentApiUrl }}</p>
        <p>Estado actual del frontend (Vue): {{ connectionState.currentFrontendUrl }}</p>

        <h2 class="mt-5">Impresiones</h2>
        <v-text-field v-model="pdfUrl" label="Invoice URL"></v-text-field>
        <p>Preview:</p>
        <iframe :src="pdfUrl" style="width: 500px; height: 500px;"></iframe>
        <br />
        <v-btn class="white--text" color="black" @click="testPrint" x-large>Print now</v-btn>
        <br />
        <v-btn color="blue" @click="getPrinters" x-large>Get printers</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import connectionService from "@/services/connectionService";

export default {
  name: "Testing",
  data() {
    return {
      pdfUrl: "https://api.premierartesanias.com/api/invoice-manager/generate-invoice-pdf/1",
      connectionState: connectionService.getConnectionState(),
    };
  },
  methods: {
    changeMode(mode) {
      connectionService.setManualMode(mode);
      this.connectionState = connectionService.getConnectionState();
    },
    async testPrint() {
      const result = await this.$printPDF(this.pdfUrl);
      if (result.success) {
        console.log("PDF enviado correctamente a la impresora.");
      } else {
        console.error("Error al imprimir PDF:", result.error);
      }
    },
    getPrinters() {
      if (window.electronAPI && typeof window.electronAPI.getPrinters === "function") {
        const printers = window.electronAPI.getPrinters();
        console.log("Lista de impresoras detectadas:", printers);
      } else {
        console.error("La función de obtener impresoras no está disponible.");
      }
    },
  },
};
</script>
