import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import 'moment/locale/es';
import axios from 'axios';
import connectionService from './services/connectionService'; // Importar el nuevo servicio

// Importar Google Fonts
const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap';
link.rel = 'stylesheet';
document.head.appendChild(link);

import './assets/style.css';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$http = connectionService.http; // Usar la instancia configurada de Axios
Vue.prototype.$apiUrl = connectionService.getConnectionState().currentApiUrl; // Configurar la URL inicial
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
moment.locale('es');

connectionService.http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!navigator.onLine) {
        console.warn('Parece que la aplicación está sin conexión.');
      }
      return Promise.reject(error);
    }
);

// Log inicial para verificar la URL base
console.log(`API URL inicial: ${Vue.prototype.$apiUrl}`);

// Forzar sincronización al cargar
// Forzar sincronización al cargar
window.addEventListener('DOMContentLoaded', async () => {
  try {
    const config = await window.electronAPI.getConfig();
    console.log("Configuración inicial desde Electron:", config);

    // Actualizar prototipos de Vue
    Vue.prototype.$apiUrl = config.backendUrl;
    Vue.prototype.$frontendUrl = config.frontendUrl;

    // Actualizar instancia de Axios
    connectionService.http.defaults.baseURL = config.backendUrl;

    console.log(`API URL sincronizada desde config.json: ${config.backendUrl}`);
    console.log(`Frontend URL sincronizada desde config.json: ${config.frontendUrl}`);
    console.log(`Modo inicial: ${config.mode}`);

    // Validar si las URLs coinciden con las actuales
    const currentApiUrl = connectionService.http.defaults.baseURL;
    const currentFrontendUrl = Vue.prototype.$frontendUrl;

    if (
        currentApiUrl !== config.backendUrl ||
        currentFrontendUrl !== config.frontendUrl
    ) {
      // Forzar recarga para aplicar nuevas URLs
      if (!window.location.search.includes('t=')) {
        console.log("Recargando para aplicar nuevas URLs.");
        window.location.href = `${window.location.origin}${window.location.pathname}?t=${Date.now()}`;
      }
    } else {
      console.log("URLs ya están sincronizadas, no se requiere recarga.");
    }
  } catch (error) {
    console.error("Error al sincronizar configuración inicial:", error.message);
  }
});



// Escuchar cambios dinámicos de modo
window.addEventListener('manualModeChange', async () => {
  try {
    const config = await window.electronAPI.getConfig();
    console.log("Configuración actualizada desde Electron:", config);

    // Actualizar prototipos de Vue
    Vue.prototype.$apiUrl = config.backendUrl;
    Vue.prototype.$frontendUrl = config.frontendUrl;

    // Actualizar instancia de Axios
    connectionService.http.defaults.baseURL = config.backendUrl;

    console.log(`API URL actualizada a: ${config.backendUrl}`);
    console.log(`Frontend URL actualizada a: ${config.frontendUrl}`);
    console.log(`Modo actualizado a: ${config.mode}`);

    // Actualizar el modo en el servicio
    connectionService.setManualMode(config.mode);

    // Forzar recarga si no se reflejan los cambios
    if (!window.location.search.includes('t=')) {
      console.log("Recargando para reflejar nuevas URLs.");
      window.location.href = `${window.location.origin}${window.location.pathname}?t=${Date.now()}`;
    }
  } catch (error) {
    console.error("Error al manejar cambio de modo:", error.message);
  }
});



// Log inicial para verificar la URL base
console.log(`API URL inicial: ${Vue.prototype.$apiUrl}`);

Vue.prototype.$printPDF = async function (pdfUrl) {
  console.log('Intentando imprimir PDF desde:', pdfUrl);

  if (window.electronAPI && typeof window.electronAPI.printTicketPDF === 'function') {
    try {
      const result = await window.electronAPI.printTicketPDF(pdfUrl);
      console.log('Resultado de impresión:', result);
      return { success: true, message: result.message || 'Impresión enviada correctamente.' };
    } catch (err) {
      console.error('Error durante la impresión:', err.message || err);
      return { success: false, error: err.message || 'Error desconocido durante la impresión.' };
    }
  } else {
    const errorMessage = 'La funcionalidad de impresión no está disponible.';
    console.error(errorMessage);
    return { success: false, error: errorMessage };
  }
};

Vue.config.devtools = true;
new Vue({
  router,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
