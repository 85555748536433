<template>
  <div>

    <v-card class="mt-3">
      <v-card-title>
        <p class="display-3 ma-0 pa-0 mb-5 ">
          Caja
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p class="ma-0 pa-0 mt-2 mb-2">{{ formattedDate }}</p>

        <v-menu
            offset-y
            transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" outlined x-large dark v-bind="attrs" v-on="on">
              <v-icon class="blue--text" left>mdi-menu</v-icon>
              <span class="blue--text">Menú de opciones</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="openDialogConcepts">
              <v-list-item-icon>
                <v-icon>mdi-new-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Crear concepto</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="openDialogRegisterVisit">
              <v-list-item-icon>
                <v-icon>mdi-invoice-text-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registrar nueva visita</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="dialogCurrency = true">
              <v-list-item-icon>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Configurar tipo de cambio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="openOpenOrderModal">
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Abrir orden de compra</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="openPagarVisitaModal">
              <v-list-item-icon>
                <v-icon>mdi-cash-100</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pagar visita</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="openPayCommissionsAgency">
              <v-list-item-icon>
                <v-icon>mdi-account-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Reporte y pago de comisiones a Agencias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn class="ml-3" x-large color="black" @click="openDialogCashClosing" outlined>
          <v-icon left>mdi-door-closed-lock</v-icon>
          Reporte Global De Caja
        </v-btn>

        <v-btn class="ml-3" x-large color="black" @click="openDialogCashFunds" outlined>
          <v-icon left>mdi-cash-plus</v-icon>
          Aportar a fondo de caja
        </v-btn>

        <v-btn class="ml-3" x-large color="black" @click="openCashRegisterHistory" outlined>
          <v-icon left>mdi-cash-register</v-icon>
          Fondo de caja
        </v-btn>

        <v-btn class="ml-3" x-large color="black" @click="openDialogCashOutflows" outlined>
          <v-icon left>mdi-cash-refund</v-icon>
          Registrar salida de dinero
        </v-btn>

        <!--        <v-btn class="ml-3" x-large color="black" @click="openDialogCashOutflows" outlined>
                  <v-icon left>mdi-arrow-top-right-bold-outline</v-icon>
                  Registrar egreso
                </v-btn>-->

        <!--        <div class="">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-btn large class="white&#45;&#45;text" color="blue darken-2" @click="openDialogConcepts" block>
                        <v-icon left>mdi-new-box</v-icon>
                        Crear concepto
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-btn large @click="openDialogRegisterVisit" color="deep-purple darken-1 white&#45;&#45;text" block>
                        <v-icon left>mdi-invoice-text-plus-outline</v-icon>
                        Registrar nueva visita
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-btn large color="grey darken-3" outlined @click="dialogCurrency = true" block>
                        <v-icon left>mdi-currency-usd</v-icon>
                        Configurar tipo de cambio
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-btn large color="green darken-2" outlined @click="openOpenOrderModal" block>
                        <v-icon left>mdi-open-in-new</v-icon>
                        Abrir orden de compra
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-btn large color="orange darken-2" outlined @click="openPagarVisitaModal" block>
                        <v-icon left>mdi-cash-100</v-icon>
                        Pagar visita
                      </v-btn>
                    </v-col>
                  </v-row>

                </div>-->
      </v-card-subtitle>
      <v-card-text>

<!--        <div>
          <iframe src="http://localhost:8000/api/invoice-manager/generate-invoice-pdf/35"></iframe>
          <v-btn @click="testPrint">Test print</v-btn>
        </div>-->

        <v-row class="mt-3">
          <v-col lg="8">
            <div v-if="currenciesChanges && currenciesChanges.length > 0">
              <v-card width="280px" class="d-inline-flex mr-5 mb-5 pa-5" elevation="1">
                <v-card-text class="d-flex align-center">
                  <v-img
                      max-width="30"
                      height="20"
                      class="mr-3 flag-img"
                      src="https://raw.githubusercontent.com/lipis/flag-icons/4390ff1d78e8bc2706305eaaec861ff26afdc8c1/flags/4x3/us.svg"
                      alt="USA Flag"
                  ></v-img>
                  <div>
                    <p class="pb-0 mb-0 light-blue--text text--darken-4 font-weight-bold">Compra dólar</p>
                    <p class="pb-0 mb-0 black--text font-weight-bold">${{ currenciesChanges[0].current_amount_mxn }}
                      <small>MXN</small></p>
                  </div>
                </v-card-text>
              </v-card>

              <v-card width="280px" class="d-inline-flex mr-5 mb-5 pa-5" elevation="1">
                <v-card-text class="d-flex align-center">
                  <v-img
                      max-width="30"
                      height="20"
                      class="mr-3 flag-img"
                      src="https://raw.githubusercontent.com/lipis/flag-icons/4390ff1d78e8bc2706305eaaec861ff26afdc8c1/flags/4x3/es.svg"
                      alt="Spain Flag"
                  ></v-img>
                  <div>
                    <p class="pb-0 mb-0 light-blue--text text--darken-4 font-weight-bold">Compra euro</p>
                    <p class="pb-0 mb-0 black--text font-weight-bold">${{ currenciesChanges[1].current_amount_mxn }}
                      <small>MXN</small></p>
                  </div>
                </v-card-text>
              </v-card>

            </div>
            <div v-else>
              <p>No se han definido monedas. Favor de definir al menos una desde la opción de "Configurar tipo de
                cambio"</p>
            </div>

            <div class="order-container-details"
                 v-if="invoiceOrderNumberFound && invoiceOrderNumberFound.trim().length > 0">
              <h1 class="text--h1 mt-2 mb-2 red--text">Orden abierta: #{{ invoiceOrderNumberFound }}</h1>
            </div>

            <v-card>
              <v-card-title>Nueva compra</v-card-title>
              <v-card-text>

                <v-btn class="white--text mb-3" @click="openBarcodeDialog" color="black" small>
                  <v-icon>mdi-barcode</v-icon> Escanear
                </v-btn>

                <v-alert dismissible color="black" outlined>Para empezar a agregar productos al carrito debe buscarlos
                  en el buscador y
                  seleccionar el de su preferencia
                </v-alert>
                <!--            <v-btn @click="addItemToCart" color="grey darken-2" class="white&#45;&#45;text" outlined>Agregar al carrito</v-btn>-->
                <v-autocomplete
                    :loading="loadingSearchingConcepts"
                    v-model="autocompleteSearch"
                    hide-no-data
                    hide-selected
                    :items="itemsConcepts"
                    item-value="id"
                    item-text="concept_name"
                    :search-input.sync="searchInput"
                    :no-filter="true"
                    filled
                    label="Escriba el nombre del concepto para agregarlo posteriormente al carrito"
                    return-object
                >
                  <!-- Template para personalizar cada opción -->
                  <template v-slot:item="data">
                    <p class="pb-0 mb-0"><strong>{{ data.item.concept_name }}</strong> ${{
                        data.item.concept_price_mxn
                      }}</p>
                    <div class="ml-3 d-flex flex-column">
                      <small>
                        <v-chip small :color="(data.item.merchant.id === 1) ? 'primary' : 'red'" dark>
                          {{ data.item.merchant.merchant_name }}
                        </v-chip>
                        <span class="ml-2"><strong>{{
                            data.item.concept_details !== 'N/A' ? data.item.concept_details : 'Sin detalles disponibles'
                          }}</strong></span>
                      </small>
                    </div>
                  </template>
                </v-autocomplete>

                <p class="mt-5" v-if="!itemsCart || itemsCart.length <= 0">El carrito de compras está vacío</p>

                <div v-if="itemsCart && itemsCart.length > 0">
                  <p class="subtitle-1 black--text">Usted agregó <strong>({{ (itemsCart) ? itemsCart.length : 0 }})
                    productos</strong> al carrito</p>

                  <v-simple-table>
                    <thead>
                    <tr>
                      <th class="text-left text-h6">Producto</th>
                      <th class="text-left text-h6">Tipo</th>
                      <!--                  <th class="text-left text-h6">Descripción</th>-->
                      <th class="text-left text-h6">Cantidades</th>
                      <th class="text-left text-h6">Precio</th>
                      <th class="text-left text-h6">Subtotal</th>
                      <th class="text-left text-h6">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(itemC, i) in itemsCart">
                      <td class="">
                        <p class="text-h6 mt-0 mb-0">{{ itemC.concept_name }}</p>
                        <small>{{ itemC.concept_details }}</small>
                      </td>
                      <td class="text-h6">
                        <div class="d-flex align-center">
                          <v-icon :color="(itemC.merchant.id !== 1) ? 'red' : 'blue'" class="mr-2">
                            mdi-label
                          </v-icon>
                          <span>{{ itemC.merchant.merchant_name }}</span>
                        </div>

                      </td>
                      <!--                  <td class="text-h6">{{ itemC.concept_details }}</td>-->
                      <td class="text-h6">
                        <v-select @change="updateAmountItemCart(itemC, i)" v-model="itemC.quantities"
                                  :items="quantitiesDefaultSelect"></v-select>
                      </td>
                      <td class="text-h6">{{ itemC.concept_price_mxn }}</td>
                      <td class="text-h6">{{ itemC.amount_item }}</td>
                      <td class="text-h6">
                        <v-btn @click="deleteItemCart(itemC, i)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>

              </v-card-text>
            </v-card>


            <v-card class="mt-3 grey lighten-5" elevation="1">
              <v-card-text>
                <v-simple-table class="grey lighten-5">
                  <tbody>
                  <tr>
                    <td class="text-h5 font-weight-light">Subtotal Mercancía</td>
                    <td class="text-h5 font-weight-light">
                      {{ number_format(this.getAmountsMerchantLiquors().amount_merchant, 2, '.', ',') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-h5 font-weight-light">Subtotal Licores</td>
                    <td class="text-h5 font-weight-light">
                      {{ number_format(this.getAmountsMerchantLiquors().amount_liquors, 2, '.', ',') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-h5 font-weight-light">
                      <strong>Subtotal:</strong>
                    </td>
                    <td class="text-h5 font-weight-light">
                      {{ number_format(cartAmounts.totalOneAmount, 2, '.', ',') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="">

                      <v-checkbox hide-details v-model="cartSettings.applyDiscountToOrder">
                        <template v-slot:label>
                          <div class="font-weight-light text-h5">
                            Aplicar descuento
                          </div>
                        </template>
                      </v-checkbox>
                      <p class="ma-0 pa-0 font-weight-bold mb-3"><small>Ingrese el monto en MXN Pesos mexicanos. El monto del descuento aplicará solo para mercancía, no para licores.</small></p>
                    </td>
                    <td class="">
                      <v-text-field
                          @keyup="calculateAmounts()"
                          :disabled="!cartSettings.applyDiscountToOrder"
                          v-model="cartAmounts.discountsAppliedMxn"
                          class="text-h5 font-weight-light"
                          type="number"
                          prepend-inner-icon="mdi-currency-usd"
                      ></v-text-field>
                    </td>
                  </tr>
                  <!--              <tr>
                                  <td class="text-h5 font-weight-light">
                                    <v-checkbox disabled v-model="activeIVATax" label="">
                                      <template v-slot:label>
                                        <div class="font-weight-light">
                                          <span class="text-h5">IVA (16%)</span> <small>Se requieren de permisos para deshabilitar esta
                                          opción</small>
                                        </div>
                                      </template>
                                    </v-checkbox>
                                  </td>
                                  <td class="text-h5 font-weight-light">
                                    {{ number_format(cartAmounts.amountTaxes, 2, '.', ',') }}
                                  </td>
                                </tr>-->
                  <tr>
                    <td class="text-h5 font-weight-light">
                      <strong>Total:</strong>
                    </td>
                    <td class="text-h5 font-weight-light">
                      <strong>
                        {{ number_format(cartAmounts.totalTwoAmount, 2, '.', ',') }}
                      </strong>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>

            </v-card>


            <v-btn :disabled="(itemsCart.length <= 0)" @click="openFinalizarCompraDialog" color="black" x-large
                   class="white--text mt-5">
              Confirmar compra
            </v-btn>


          </v-col>
          <v-col lg="4">

            <v-card class="mt-5" elevation="0">
              <v-card-title class="light-blue--text text--darken-4">Acciones rápidas</v-card-title>
              <v-card-text>
                <v-btn text class="mb-5 mr-2" @click="openDialogRegisterVisit" large>
                  <v-icon>mdi-open-in-new</v-icon> <span class="text-decoration-underline">Registrar nueva visita</span>
                </v-btn>

                <v-btn text class="mb-5 mr-2" @click="openPagarVisitaModal" large>
                  <v-icon>mdi-open-in-new</v-icon> <span class="text-decoration-underline">Pagar visita</span>
                </v-btn>

                <v-btn text class="mb-5 mr-2" @click="openOpenOrderModal" large>
                  <v-icon >mdi-open-in-new</v-icon> <span class="text-decoration-underline">Abrir orden de compra</span>
                </v-btn>

              </v-card-text>
            </v-card>

<!--            <v-card class="mt-5" elevation="1">
              <v-card-title class="light-blue&#45;&#45;text text&#45;&#45;darken-4">Últimas compras</v-card-title>
              <v-card-text>
                <div v-if="loadingFinishedOrders">
                  <v-progress-linear
                      indeterminate
                      color="primary"
                  ></v-progress-linear>
                </div>

                <div v-else>
                  <div v-if="(!lastFinishedOrders || lastFinishedOrders.length <= 0)">
                    No se encontraron órdenes finalizadas
                  </div>

                  <v-list v-for="(itemOrder, i) in lastFinishedOrders">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> {{ moment(itemOrder.created_at).format('LLL') }} •
                          <small>{{ itemOrder.invoice_string_id }}</small></v-list-item-title>
                        <v-list-item-title>
                          <strong>${{ number_format(itemOrder.amount_original, 2, '.', ',') }} MXN</strong> <span
                            class="light-blue&#45;&#45;text text&#45;&#45;darken-4">{{
                            (itemOrder.currency.id !== 1) ? ` • $${itemOrder.amount_converted} ${itemOrder.currency.currency_iso}` : ''
                          }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="( (i +1) <= 5)"></v-divider>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>-->

            <v-card class="mt-5" elevation="1">
              <v-card-title class="light-blue--text text--darken-4">Órdenes de compra</v-card-title>
              <v-card-text>
                <div v-if="loadingOpenedOrders">
                  <v-progress-linear
                      indeterminate
                      color="primary"
                  ></v-progress-linear>
                </div>
                <div v-else>
                  <div v-if="(!lastOpenedOrders || lastOpenedOrders.length <= 0)">
                    No se encontraron órdenes abiertas
                  </div>
                  <v-list v-for="(itemOrder, i) in lastOpenedOrders">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ moment(itemOrder.created_at).format('LLL') }} • <small>Orden
                          pendiente</small>
                        </v-list-item-title>
                        <v-list-item-content>
                          <p class="display-1">{{ itemOrder.order_opened_id }}</p>
                        </v-list-item-content>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="( (i +1) <= 5)"></v-divider>
                  </v-list>
                </div>

              </v-card-text>
            </v-card>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--    <h1 class="text-h2 text&#45;&#45;secondary">
          <v-icon x-large aria-hidden="false"> mdi-shopping-outline</v-icon>
          Caja
        </h1>-->

    <v-dialog
      v-model="dialogBarcode"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>Escanear concepto</v-card-title>
        <v-card-text>
          <v-alert color="black" outlined dismissible>
            Con su código de barras, escanee el código teniendo esta ventana abierta.
          </v-alert>

          <v-text-field
              @keyup="onEnterPress"
              ref="conceptIdField"
              placeholder="ID del concepto"
              v-model="conceptIdScanText"
          ></v-text-field>

          <v-btn @click="addItemToCartScan" large color="black" outlined >
            Confirmar
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogBarcode = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog for adding ITEMS TO CART -->
    <v-dialog
        v-model="dialogPagarVisita"
        persistent
        max-width="1200px"
    >
      <v-card>
        <!-- Agregar ícono de cerrar en la parte superior derecha -->
        <v-btn icon @click="dialogPagarVisita = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>Pagar visita a guía</v-card-title>
        <v-card-text>

          <v-alert  color="black" outlined dismissible>
            A continuación seleccione la visita vinculada al guía, vendedor y color. De esta forma se podrá calcular
            el pago de sus comisiones.
          </v-alert>

          <!--          <v-alert type="success" outlined dense v-if="showBusinessCodeResultSuccess" dismissible>
                      El cliente es válido y se vinculará a la compra una vez finalizada la compra
                    </v-alert>-->
          <div v-if="showGuidePayInvoiceIframe">
            <v-alert color="black" outlined>
              <strong>Atención.</strong> Favor de <strong>imprimir</strong> el recibo generado de esta orden.
              Gracias.
            </v-alert>
            <iframe style="width:100%;height:500px;" :src="getGuidePayUrlInvoice()"></iframe>

            <v-btn
                @click="resetGuidePay"
                color="black"
                elevation="0"
                x-large
                class="white--text mt-3" small>Confirmar y generar otro pago
            </v-btn>

          </div>



          <div v-if="!showGuidePayInvoiceIframe" class="">
<!--            <p class="text-h5 mt-5 black&#45;&#45;text font-weight-bold">Visitas del día</p>-->
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="invoiceRegisterDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="formattedDateRegisterVisit"
                    label="Seleccionar una fecha diferente"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="invoiceRegisterDate"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="getCurrentVisitsByDate(invoiceRegisterDate)"
                >
                  Seleccionar
                </v-btn>
              </v-date-picker>
            </v-menu>


            <div v-if="loadingPayVisitResults">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Cargando...
            </div>

            <div v-if="!loadingPayVisitResults">


              <template v-if="currentRegisterVisitPay">
                <v-chip
                    class="ma-2"
                    close
                    @click:close="resetSelectRegisterVisit"
                >
                  Guía elegido: {{ currentRegisterVisitPay.guide.guide_name }}
                  (<strong>{{ currentRegisterVisitPay.guide.unique_code_business }}</strong>)
                </v-chip>
              </template>

              <template v-if="!currentRegisterVisitPay">
                <SearchVisitTable :origin-action="`pay-guide-visit`" :items-visits-inside="itemsVisitsInside" @select-item-action="selectRegisterVisitPay"></SearchVisitTable>
              </template>


            </div>


            <div v-if="currentRegisterVisitPay">

              <h2 class="pt-2 pb-2 black--text">Desglose detallado de las ventas y comisiones</h2>
              <div>
                <v-btn @click="getDetailsGuideVisitSales(currentRegisterVisitPay.id)" class="white--text" color="black" large>Click para desglosar</v-btn>
              </div>

              <div v-if="loadingDetailsGuideVisitSales">
                Cargando detalles de venta, espere, por favor...
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
              </div>

              <template v-if="detailsGuideVisitSales">
                <DetailsGuidePayVisit :details-guide-visit-sales="detailsGuideVisitSales"></DetailsGuidePayVisit>

                <v-divider class="mt-5 mb-5"></v-divider>
              </template>

              <ResumeGuidePay :current-register-visit-pay="currentRegisterVisitPay" :invoice-register-date="invoiceRegisterDate"></ResumeGuidePay>

              <v-btn
                  :disabled="loadingPayGuideVisit || (currentRegisterVisitPay.guide_pay_visit !== null)"
                  @click="confirmPayGuideVisitAll"
                  color="black"
                  elevation="0"
                  x-large
                  class="white--text mt-3" small>Realizar pago total de comisiones
              </v-btn>
              <v-alert class="mt-2" outlined dismissible type="success"
                       v-if="(currentRegisterVisitPay.guide_pay_visit)">
                Este recibo ya se encuentra pagado. <a target="_blank" :href="getGuidePayUrlInvoice()">Ver o descargar
                recibo de pago</a>
              </v-alert>

              <!--              <v-btn
                                :disabled="loadingPayGuideVisit"
                                @click="confirmPayGuideVisit"
                                color="black"
                                elevation="0"
                                x-large
                                class="white&#45;&#45;text mt-3" small>Realizar pago de visita por
                              ${{ getCalculateFinalVisitCommission(payVisitGuide.guideAgencyFound) }} MXN
                            </v-btn>-->
              <div v-if="loadingPayGuideVisit">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                Generando pago, espere...
              </div>
            </div>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogPagarVisita = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog for adding ITEMS TO CART -->
    <v-dialog
        v-model="dialogAddItemCart"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>Agregar items al carrito de compra</v-card-title>
        <v-card-actions>
          <v-btn text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogCurrency"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>Configurar tipo de cambio</v-card-title>
        <v-card-text>
          <v-alert color="black" dismissible outlined><strong>Atención.</strong> Capture en este formulario el valor de
            las monedas. Estos
            valores por moneda se utilizarán al momento del cobro en la caja.
          </v-alert>
          <!--mxn-->
          <v-row>
            <v-col lg="4">
              <label for="">mxn (Pesos Mexicanos) - Default</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 MXN equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyMXN.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(1)" class="mt-2 white--text" color="black">Guardar MXN</v-btn>
            </v-col>
          </v-row>
          <!--USD-->
          <v-row>
            <v-col lg="4">
              <label for="">USD (Dólares)</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 USD equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyUSD.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(2)" class="mt-2 white--text" color="black">Guardar USD</v-btn>
            </v-col>
          </v-row>
          <!--EUR-->
          <v-row>
            <v-col lg="4">
              <label for="">EUR (Euros)</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 EUR equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyEUR.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(3)" class="mt-2 white--text" color="black">Guardar EUR</v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>
          <p class="text-h5 black--text font-weight-bold">Historial de tipos de cambio</p>
          <v-data-table :items="historyCurrencyChanges" :headers="headersHistoryCurrency"></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCurrency = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog for capturing FINANCIAL CONCEPTS-->
    <v-dialog
        v-model="dialogConcepts"
        persistent
        max-width="800px">
      <v-card>
        <v-card-title>Crear nuevo concepto</v-card-title>
        <v-card-text>

          <v-tabs v-model="conceptsTabs">
            <v-tab>
              Crear nuevo concepto
            </v-tab>
            <v-tab>Historial de conceptos</v-tab>
          </v-tabs>

          <v-tabs-items v-model="conceptsTabs">
            <v-tab-item>
              <v-alert color="black" class="mt-3" outlined dismissible>Los conceptos que capture y administre aquí, se
                verán reflejados en el área de
                caja. Para eliminar o editar,
                <a href="#!">visite el módulo de conceptos</a>
              </v-alert>
              <v-alert v-model="showSavedMessage" type="success" dismissible>El concepto se creó correcamente</v-alert>
              <v-alert v-model="showErrorMessage" type="error" dismissible>Ocurrió un error al guardar, por favor
                inténtelo
                de nuevo más tarde
              </v-alert>

              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="financialConceptForm.conceptName"
                                label="Por favor captura el nombre del concepto"
                                placeholder="Ejemplo: TAZA DE VIDRIO BLANCA"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="financialConceptForm.conceptPriceMxn" type="number" step="0.01"
                                label="Precio expresado en MXN"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="financialConceptForm.merchantId" label="Tipo de mercancía" :items="merchantList"
                            item-value="id" item-text="merchant_name"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="financialConceptForm.conceptDetails" :rows="5"
                              placeholder="Ejemplo: TAZA FABRICADA DE VIDRIO DE 20X20cm COLOR AMARILLA"
                              label="Descripción del concepto (opcional)"></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                  color="black"
                  class="white--text"
                  x-large
                  v-if="!loadingStoreConcept"
                  @click="storeFinancialConcept">Guardar
              </v-btn>

              <v-progress-linear
                  v-if="loadingStoreConcept"
                  indeterminate
                  color="primary"
              ></v-progress-linear>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-title>
                  Listado de conceptos
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="searchConcepts"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    :search="searchConcepts"
                    :headers="headersConcepts"
                    :items="itemsConceptsHistory"
                    :items-per-page="5"
                    class="elevation-1"
                    :loading="loadingConcepts"
                >
                  <template v-slot:item.created_at="{ item }">
                    {{ formatFriendlyDate(item.created_at) }}
                  </template>

                  <template v-slot:item.print_barcode_concept="{item}">
                    <v-btn :href="getConceptBarcode(item)" target="_blank" outlined color="black" small>
                      <v-icon>mdi-open-in-new</v-icon>
                      Imprimir
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogConcepts = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogOpenOrder" max-width="800px">
      <v-card>

        <!-- Agregar ícono de cerrar en la parte superior derecha -->
        <v-btn icon @click="dialogOpenOrder = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>Abrir orden de compra</v-card-title>
        <v-card-text>

          <v-alert v-if="showOrderNoFoundError" type="error" outlined>No se encontraron coincidencias con este número de
            orden
          </v-alert>

          <v-text-field v-model="invoiceOrderNumber" label="Ingresa el número de orden"
                        placeholder="Ej: OOR_0000001"></v-text-field>
          <v-btn @click="searchOrderShoppingCart" :disabled="loadingSearchingOrder" color="black" class="white--text"
                 x-large>Confirmar y reabrir orden
          </v-btn>

          <div v-if="loadingSearchingOrder">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Buscando, espere...
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpenOrder = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogRegisterVisit" max-width="1200px">
      <v-card>

        <!-- Agregar ícono de cerrar en la parte superior derecha -->
        <v-btn icon @click="dialogRegisterVisit = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>Registrar nueva visita</v-card-title>
        <v-card-text>

          <v-card elevation="0">
            <v-card-text>

              <v-alert color="black" outlined dense dismissible>
                A continuación ingrese los datos para crear la nueva visita. <strong>Las visitas del día podrá
                revisarlas en el historial de visitas del día de hoy</strong>
              </v-alert>


              <!--              <v-select
                                v-model="registerVisitForm.guideAgency"
                                :items="itemsGuidesAgencies"
                                label="Buscar guía o agencia"
                                menu-props="auto"
                                return-object
                                item-value="id"
                                item-text="custom_guide_agency_name"
                                hide-details
                                single-line
                            >
                            </v-select>-->

              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <h2 class="pt-2 pb-2 black--text">Detalles del guía o agencia</h2>
                  <template v-if="registerVisitForm && registerVisitForm.guideAgency">
                    <v-chip
                        class="ma-2"
                        close
                        @click:close="resetSelectRegisterVisit"
                    >
                      Guía elegido: {{ registerVisitForm.guideAgency.guide_name }}
                      (<strong>{{ registerVisitForm.guideAgency.unique_code_business }}</strong>)
                    </v-chip>
                  </template>
                  <template v-else>
                    <v-autocomplete
                        hide-details
                        outlined
                        label="Escriba el nombre del guía o agencia para iniciar la búsqueda"
                        placeholder="Ej: ESTUR o JUANITO PEREZ"
                        v-model="registerVisitForm.guideAgency"
                        :items="itemsGuidesAgencies"
                        item-value="id"
                        item-text="custom_guide_agency_name"
                        :search-input.sync="searchQuery"
                        :no-filter="true"
                        hide-no-data
                        hide-selected
                        return-object
                    >
                      <template v-slot:item="data">
                        {{ data.item.guide_name }} (<strong>{{ data.item.unique_code_business }}</strong>) <span
                          class="ml-3 blue--text"><small>{{
                          data.item.agency.agency_person_in_charge_name
                        }}</small></span>
                      </template>
                    </v-autocomplete>
                  </template>
                </v-col>

                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-text-field
                      class="ma-0 pa-0"
                      min="1"
                      type="number"
                      outlined
                      v-model="registerVisitForm.numberPeople"
                      label="Número de personas/pax para esta venta (personas que trae el guía o agencia)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="12" md="12" sm="12">
                  <h2 class="pt-2 pb-2 black--text">Detalles sobre los vendedores que atienden esta visita</h2>
                  <template>
                    <v-text-field
                        outlined
                        hint="Dé Enter para continuar"
                        label="¿Cuántos vendedores atienden esta visita?"
                        placeholder="0"
                        type="number"
                        @change="updateNumberSalesUsers"
                        v-model="registerVisitForm.numberOfSaleUsers"></v-text-field>


                    <!--Extra sales users-->
                    <div v-if="registerVisitForm.salesUsersVisit && registerVisitForm.salesUsersVisit.length > 0">
                      <div class="mt-5" v-for="(itemExtraSaleUser, indexSaleUser) in registerVisitForm.salesUsersVisit">
                        <v-row>
                          <v-col lg="6" md="6" xs="12">


                            <template v-if="!itemExtraSaleUser.manual_capture_color">
                              <v-select
                                  outlined
                                  :items="colors"
                                  label="Selecciona un color"
                                  item-text="color_name"
                                  item-value="id"
                                  v-model="itemExtraSaleUser.color_id"
                                  dense
                              >
                                <!-- Slot para personalizar cada opción de la lista -->
                                <template v-slot:item="{ item, on, attrs }">
                                  <v-list-item v-bind="attrs" v-on="on">
                                    <v-list-item-avatar class="ma-0 pa-0">
                                      <div
                                          :style="{
                                backgroundColor: item.hexadecimal_value,
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                border: '1px solid #000000',
                              }"
                                      ></div>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                                  </v-list-item>
                                </template>

                                <!-- Slot para personalizar la selección cuando está cerrado el select -->
                                <template v-slot:selection="{ item }">
                                  <v-list-item>
                                    <v-list-item-avatar class="ma-0 pa-0">
                                      <div
                                          :style="{
                                backgroundColor: item.hexadecimal_value,
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                border: '1px solid #000000'
                              }"
                                      ></div>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </template>
                            <template v-else>
                              <v-text-field ref="manualColorInput" placeholder="Escriba el color" v-model="itemExtraSaleUser.manual_capture_text"></v-text-field>
                            </template>

                            <div>
                              <v-switch
                                  class="ma-0 pa-0"
                                  v-model="itemExtraSaleUser.manual_capture_color"
                                  :label="`Capturar manualmente el color`"
                              ></v-switch>
                            </div>
                          </v-col>
                          <v-col lg="6" md="6" xs="12">
                            <v-select
                                :items="listSaleUsers"
                                :label="`Especifique el vendedor #${indexSaleUser+1}`"
                                item-text="sale_user_fullname"
                                item-value="id"
                                v-model="itemExtraSaleUser.sale_user_id"
                                :key="indexSaleUser"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </div>

                    </div>
                  </template>
                </v-col>


<!--                <v-col cols="12" lg="6" md="6" sm="12">

                  <template v-if="!registerVisitForm.manualCaptureColor">
                  <v-select
                      outlined
                      :items="colors"
                      label="Selecciona un color"
                      item-text="color_name"
                      item-value="id"
                      v-model="registerVisitForm.selectedColor"
                      dense
                      return-object
                  >
                    &lt;!&ndash; Slot para personalizar cada opción de la lista &ndash;&gt;
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-avatar class="ma-0 pa-0">
                          <div
                              :style="{
                                backgroundColor: item.hexadecimal_value,
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                border: '1px solid #000000',
                              }"
                          ></div>
                        </v-list-item-avatar>
                        <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                      </v-list-item>
                    </template>

                    &lt;!&ndash; Slot para personalizar la selección cuando está cerrado el select &ndash;&gt;
                    <template v-slot:selection="{ item }">
                      <v-list-item>
                        <v-list-item-avatar class="ma-0 pa-0">
                          <div
                              :style="{
                                backgroundColor: item.hexadecimal_value,
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                border: '1px solid #000000'
                              }"
                          ></div>
                        </v-list-item-avatar>
                        <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                  </template>
                  <template v-else>
                    <v-text-field ref="manualColorInput" placeholder="Escriba el color" v-model="registerVisitForm.manualColorText"></v-text-field>
                  </template>

                  <div>
                    <v-switch
                        class="ma-0 pa-0"
                        v-model="registerVisitForm.manualCaptureColor"
                        :label="`Capturar manualmente el color`"
                    ></v-switch>
                  </div>

                </v-col>-->

                <!--                <v-col cols="12" lg="12" md="12" sm="6">
                                  <v-alert color="black" outlined dismissible>
                                    A continuación elija a los vendedores vinculados con esta visita.
                                  </v-alert>
                                  <v-select
                                      label="Cantidad de vendedores"
                                      v-model="registerVisitForm.numberOfSaleUsers"
                                      :items="[1,2,3,4,5,6,7,8,9,10]"
                                      @change="addSaleUserToRegisterVisit()"
                                  >
                                  </v-select>
                                </v-col>

                                <template
                                    v-if="registerVisitForm.saleUsersSelected && registerVisitForm.saleUsersSelected.length > 0"
                                    v-for="(itemSaleUserSelected, index) in registerVisitForm.saleUsersSelected">
                                  <v-col cols="12" lg="6" md="6" sm="6">
                                    <v-select
                                        :key="index"
                                        :items="listSaleUsers"
                                        label="Vendedor que lo atiende"
                                        item-text="sale_user_fullname"
                                        item-value="id"
                                        v-model="registerVisitForm.saleUsersSelected[index].sales_user_id"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="6">
                                    <v-select
                                        :items="colors"
                                        label="Selecciona un color"
                                        item-text="color_name"
                                        item-value="id"
                                        v-model="registerVisitForm.saleUsersSelected[index].color_id"
                                        dense
                                    >
                                      &lt;!&ndash; Slot para personalizar cada opción de la lista &ndash;&gt;
                                      <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item v-bind="attrs" v-on="on">
                                          <v-list-item-avatar class="ma-0 pa-0">
                                            <div
                                                :style="{
                                                backgroundColor: item.hexadecimal_value,
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '50%',
                                                border: '1px solid #000000',
                                              }"
                                            ></div>
                                          </v-list-item-avatar>
                                          <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                                        </v-list-item>
                                      </template>

                                      &lt;!&ndash; Slot para personalizar la selección cuando está cerrado el select &ndash;&gt;
                                      <template v-slot:selection="{ item }">
                                        <v-list-item>
                                          <v-list-item-avatar class="ma-0 pa-0">
                                            <div
                                                :style="{
                                                backgroundColor: item.hexadecimal_value,
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '50%',
                                                border: '1px solid #000000'
                                              }"
                                            ></div>
                                          </v-list-item-avatar>
                                          <v-list-item-content class="ma-0 pa-0">{{ item.color_name }}</v-list-item-content>
                                        </v-list-item>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </template>-->
                <!--                <v-col cols="12" lg="6" md="6" sm="6">
                                  <template
                                      v-if="registerVisitForm.saleUsersSelected && registerVisitForm.saleUsersSelected.length > 0">
                                    <v-select
                                        v-for="(itemSaleUserSelected, index) in registerVisitForm.saleUsersSelected"
                                        :key="index"
                                        :items="listSaleUsers"
                                        label="Vendedor que lo atiende"
                                        item-text="sale_user_fullname"
                                        item-value="id"
                                        v-model="registerVisitForm.saleUsersSelected[index]"
                                        return-object
                                    >
                                    </v-select>
                                  </template>
                                  <template v-else>
                                    Esperando selección de número de vendedores
                                  </template>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="6">
                                  <template
                                      v-if="registerVisitForm.saleUsersSelected && registerVisitForm.saleUsersSelected.length > 0">
                                    <v-select
                                        v-for="(itemSaleUserSelected, index) in registerVisitForm.saleUsersSelected"
                                        :key="index"
                                        :items="listSaleUsers"
                                        label="Vendedor que lo atiende"
                                        item-text="sale_user_fullname"
                                        item-value="id"
                                        v-model="registerVisitForm.saleUsersSelected[index]"
                                        return-object
                                    >
                                    </v-select>
                                  </template>
                                  <template v-else>
                                    Esperando selección de número de vendedores
                                  </template>
                                </v-col>-->

                <!--
                                <v-col cols="12" lg="12" md="12" sm="12">
                                  <v-select
                                      v-model="registerVisitForm.numberPeople"
                                      label="Número de personas que trae el guía o agencia"
                                      :items="[1,2,3,4,5,6,7,8,9,10]"
                                  >

                                  </v-select>
                </v-col>
-->

                <v-col cols="12" lg="12" md="12" sm="12">
                  <h2 class="pt-2 pb-2 black--text">Detalles sobre los chóferes/operadores de esta venta</h2>
                  <v-radio-group
                      class="ma-0 pa-0"
                      v-model="registerVisitForm.guideComeWithDriver"
                      label="¿Trae chófer/operador?"
                      row
                  >
                    <v-radio
                        label="Sí"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="No"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>

                  <template v-if="registerVisitForm.guideComeWithDriver">
                    <v-text-field
                        outlined
                        min="1"
                        type="number"
                        hint="Dé Enter para continuar"
                        label="¿Más de un solo chófer? Especifique la cantidad exacta."
                        placeholder="0"
                        v-model="registerVisitForm.numberOfDrivers"
                        @keyup="updateNumberOfDrivers()"
                    ></v-text-field>
                    <div class="pa-5" style="border: 1px solid #f2f2f2;">
                      <small class="pa-0 ma-0"><v-icon>mdi-information-slab-circle-outline</v-icon> (Opcional) Capture los nombres de los chóferes</small>
                      <template v-if="registerVisitForm.driversNames && registerVisitForm.driversNames.length > 0">
                        <v-text-field
                            :key="indexD"
                            v-for="(iD, indexD) in registerVisitForm.driversNames"
                            v-model="registerVisitForm.driversNames[indexD]"
                            placeholder="Nombre completo">
                        </v-text-field>
                      </template>
                    </div>
                  </template>
                </v-col>

                <v-col cols="12" lg="12" md="12" sm="12">
                  <h2 class="pt-2 pb-2 black--text">Notas adicionales sobre esta visita</h2>
                  <v-textarea v-model="registerVisitForm.additionalNotes"
                              label="Notas adicionales (opcional)"></v-textarea>
                </v-col>
              </v-row>

              <v-btn @click="storeRegisterVisit()"
                     :disabled="loadingRegisteringVisit"
                     color="black"
                     class="white--text"
                     x-large>Confirmar registro
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card elevation="1">
            <v-card-title class="black--text"><v-icon class="black--text">mdi-calendar-star</v-icon> Visitas del día de hoy</v-card-title>
            <v-card-text>
              <v-data-table
                  :loading="loadingRegisteringVisit"
                  :headers="headersVisits"
                  :items="itemsVisits"
              >
                <template v-slot:item.order="{item, index}">
                  {{ index + 1 }}
                </template>
    <!--            <template v-slot:item.color_register_visit="{item}">
                  <div style="display: inline-flex; align-items: center;">
                    <template v-if="(item.color_identifier_id && parseInt(item.color_identifier_id) !== 0)">
                    <div
                        :style="{
                          backgroundColor: item.color_register_visit.hexadecimal_value,
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          border: '1px solid #000000',
                          marginRight: '8px'  // Espacio entre el círculo y el texto
                        }"
                    ></div>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                </template>-->
                <template v-slot:item.come_with_driver_string="{item}">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>Respuesta: </td>
                        <th>{{item.come_with_driver_string}}</th>
                      </tr>
                      <template v-if="item.number_of_drivers && parseInt(item.number_of_drivers) > 0">
                        <tr>
                          <td>Cantidad: </td>
                          <th>{{item.number_of_drivers}}</th>
                        </tr>
                        <template v-if="item.driver_details && item.driver_details.length > 0">
                          <tr>
                            <td>Detalles: </td>
                            <th>
                              <v-simple-table>
                                <tr v-for="itemD in item.driver_details">
                                  <td class="pt-2 pb-2">{{ itemD.driver_fullname }}</td>
                                </tr>
                              </v-simple-table>
                            </th>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-slot:item.sales_users_register_visit="{item}">
                  <v-simple-table>
                    <thead>
                    <tr>
                      <th>Color</th>
                      <th>Nombre</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="itemSalesUserRegisterVisit in item.sales_users_register_visit">
                      <td class="">
                        <template v-if="registerVisitHasColorAssigned(itemSalesUserRegisterVisit)">
                          <template>
                              <div
                              :style="{
                              backgroundColor: itemSalesUserRegisterVisit.color.hexadecimal_value,
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              border: '1px solid #000000',
                              marginRight: '8px'  // Espacio entre el círculo y el texto
                            }"
                            ></div>
                          </template>
                        </template>
                        <template v-else>
                          {{itemSalesUserRegisterVisit.manual_color_text}}
                        </template>

                      </td>
                      <td class="">
                        {{itemSalesUserRegisterVisit.sale_user.sale_user_fullname}}
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  {{ formatFriendlyDate(item.updated_at) }}
                </template>
                <template v-slot:item.status_visit_string="{ item }">
                  <v-select
                      @change="updateStatusVisit(item)"
                      item-value="id"
                      item-text="name"
                      v-model="item.status_visit_id" :items="itemsOptionsStatusVisit"></v-select>
                </template>

                <template v-slot:item.guide.guide_name="{item}">
                  <p class="font-weight-bold ma-0 pa-0">{{ item.guide.guide_name }}</p>
                  (<a href="#!">{{item.guide.unique_code_business}}</a>)
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogRegisterVisit = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="ordenCompraDialog" max-width="800px">
      <v-card>
        <v-card-title>Orden de compra</v-card-title>
        <v-card-text>
          <v-alert color="black" outlined>
            <strong>Atención.</strong> Por favor tenga en cuenta lo siguiente antes de crear una orden de compra:
            <ol>
              <li>La orden de compra estará vigente hasta 48 horas, luego de ese periodo se necesitará crear un pedido
                nuevo
              </li>
              <li>Al crear una orden de compra deberá volver a capturar los montos de pago, así como las exhibiciones y
                sus montos
              </li>
              <li>Utilice las órdenes de compra como última alternativa de venta</li>
              <li>El sistema le proporcionará un folio único a través de un ticket que podrá imprimir y proporcionar al
                cliente. El cliente deberá presentar este ticket para poder abrir el pedido nuevamente desde la caja.
              </li>
            </ol>
          </v-alert>

          <v-textarea
              counter
              v-model="orderShoppingCart.additionalComments"
              :rules="rulesCommentsOrder"
              outlined
              name="input-7-4"
              label="Agregue alguna nota adicional si lo necesita o deje como está para continuar"
          ></v-textarea>

          <v-btn
              :disabled="loadingOrderCreation"
              @click="confirmOrderShoppingCart"
              color="light-blue darken-4"
              class="white--text"
              x-large outlined>Proceder a crear la orden de compra
          </v-btn>

        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn @click="ordenCompraDialog = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="finalizarCompraDialog" max-width="1200px">
      <v-card>
        <v-btn icon @click="finalizarCompraDialog = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>Finalizar compra</v-card-title>
        <v-card-text>

          <div v-if="!orderClosed() && !invoiceDetailsCreated">
            <p><a @click="showHideOrderResume = !showHideOrderResume" href="#!">Mostrar/Ocultar Resumen de Compra</a>
            </p>
            <div v-if="showHideOrderResume">
              <v-simple-table>
                <thead>
                <tr>
                  <td colspan="4">Detalles en pesos mexicanos</td>
                </tr>
                <tr>
                  <th>Descripción</th>
                  <th>Unidades</th>
                  <th>Precio</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in itemsCart">
                  <td>{{ item.concept_name }}</td>
                  <td>{{ item.quantities }}</td>
                  <td>{{ item.concept_price_mxn }}</td>
                  <td>{{ number_format(parseFloat(item.concept_price_mxn * item.quantities), 2, '.', ',') }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                <tr class="grey lighten-3">
                  <td>Subtotal:</td>
                  <td>{{ number_format(cartAmounts.totalOneAmount, 2, '.', ',') }}</td>
                </tr>
                <!--                <tr class="grey lighten-3">
                                  <td>Impuestos:</td>
                                  <td>{{ number_format(cartAmounts.amountTaxes, 2, '.', ',') }}</td>
                                </tr>-->
                <tr class="grey lighten-3">
                  <td>Total:</td>
                  <td>{{ number_format(cartAmounts.totalTwoAmount, 2, '.', ',') }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <div>
                <v-simple-table>
                  <thead>
                  <tr>
                    <td colspan="4">Detalles en moneda {{ paymentDetails.currencyTransaction.currency_iso }}</td>
                  </tr>
                  <tr>
                    <th>Descripción</th>
                    <th>Unidades</th>
                    <th>Precio</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in itemsCart">
                    <td>{{ item.concept_name }}</td>
                    <td>{{ item.quantities }}</td>
                    <td>{{ item.concept_price_converted_unit }}</td>
                    <td>{{
                        number_format(parseFloat(item.concept_price_converted_unit * item.quantities), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                  <tr class="grey lighten-3">
                    <td>Subtotal:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  <tr class="grey lighten-3">
                    <td>Impuestos:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  <tr class="grey lighten-3">
                    <td>Total:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>

            <p class="text-h5 mt-5 black--text font-weight-bold">Detalles del comprador</p>

            <!--            <v-alert type="error" outlined dense v-if="showBusinessCodeResultError" dismissible>
                          No se encontraron coincidencias con el código de cliente ingresado
                        </v-alert>-->
            <v-radio-group
                label="¿Esta compra debe vincularse a un guía o agencia?"
                v-model="paymentDetails.isOrderByGuideAgency"
                row
            >
              <v-radio
                  label="Sí"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="No"
                  :value="2"
              ></v-radio>
            </v-radio-group>
            <!--This view shows in the checkout-->
            <div v-if="paymentDetails.isOrderByGuideAgency === 1">

              <v-alert type="success" outlined dense v-if="showBusinessCodeResultSuccess" dismissible>
                El cliente es válido y se vinculará a la compra una vez finalizada la compra
              </v-alert>


              <template v-if="paymentDetails.guideAgencyFound">
                <v-chip
                    class="mt-0 mb-0"
                    close
                    @click:close="resetSelectGuideAgencyCommission"
                >
                  Guía elegido: {{ paymentDetails.guideAgencyFound.guide.guide_name }}
                  (<strong>{{ paymentDetails.guideAgencyFound.guide.unique_code_business }}</strong>)
                  ${{ getFinalCommissionTotalAgency() }}
                </v-chip>

              </template>

              <template v-else>

                <SearchVisitTable :origin-action="`checkout-view`" :items-visits-inside="itemsVisitsInside" @select-item-action="linkGuideAgencyToOrder"></SearchVisitTable>

              </template>

              <!--              <v-btn
                                @click="searchBusinessCode"
                                color="black" elevation="0" class="white&#45;&#45;text mb-1" small>Validar código
                            </v-btn>

                            <div v-if="searchingBusinessCode">
                              <v-progress-circular
                                  indeterminate
                                  color="primary"
                              ></v-progress-circular>
                              Buscando, espere...
                            </div>
                            <div v-else>
                              <v-text-field
                                  v-model="paymentDetails.agencyGuideBusinessCode"
                                  label="Ingrese el código de guía o cliente"
                                  outlined
                                  clearable
                              ></v-text-field>
                            </div>-->

              <div class="" v-if="paymentDetails.guideAgencyFound">

                <p class="pa-0 ma-0 mt-3 black--text"><small><a
                    @click="showCommissionsDetailsCheckout = !showCommissionsDetailsCheckout"
                    href="#!">({{ (showCommissionsDetailsCheckout) ? 'Ocultar' : 'Mostrar' }} desglose cálculo de
                  comisiones)</a></small></p>

                <template v-if="showCommissionsDetailsCheckout">
                  <div class="simple-table-border pa-5">
                    <p class="pa-0 ma-0 text-h5 black--text font-weight-bold">Desglose del cálculo de comisión</p>

                    <template v-if="commissionMerchant && commissionMerchant.amount_commission > 0">
                      <p class="text-h7 pa-0 mt-2 mb-0 black--text font-weight-bold">
                        <v-icon>mdi-cart-outline</v-icon>
                        Comisión por mercancía
                      </p>
                      <v-simple-table>
                        <thead>
                        <tr>
                          <th>Nombre guía</th>
                          <th>Porcentaje comisión final</th>
                          <th>Monto de compra</th>
                          <th>Descuentos aplicados</th>
                          <th>Comisión total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ (paymentDetails.guideAgencyFound.guide.guide_name) }}</td>
                          <td>
                            <v-text-field
                                label="Modificar comisión si es necesario"
                                hide-details="auto"
                                v-model="paymentDetails.guideAgencyFound.guide.finalCommissionMerchant"
                            ></v-text-field>
                            <span><strong>Comisión sugerida: </strong>{{
                                paymentDetails.guideAgencyFound.guide.guide_daily_commission_amount
                              }}%</span>
                          </td>
                          <td>${{ number_format(commissionMerchant.amount_total, 2, '.', ',') }}</td>
                          <td>${{ number_format(this.cartAmounts.discountsAppliedMxn, 2, '.', ',') }}</td>
                          <td>
                            ${{ number_format(getOnlyCommissionMerchant(), 2, '.', ',') }}
                          </td>
                        </tr>
                        </tbody>

                      </v-simple-table>
                    </template>

                    <v-divider></v-divider>
                    <template v-if="commissionLiquors && commissionLiquors.amount_commission > 0">
                      <p class="text-h7 pa-0 mt-2 mb-0 black--text font-weight-bold">
                        <v-icon>mdi-bottle-wine</v-icon>
                        Comisión por licores
                      </p>
                      <v-simple-table>
                        <thead>
                        <tr>
                          <th>Nombre guía</th>
                          <th>Porcentaje comisión final</th>
                          <th>Monto de compra</th>
                          <th>Descuentos aplicados</th>
                          <th>Comisión total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ (paymentDetails.guideAgencyFound.guide.guide_name) }}</td>
                          <td>
                            <v-text-field
                                label="Modificar comisión si es necesario"
                                hide-details="auto"
                                v-model="paymentDetails.guideAgencyFound.guide.finalCommissionLiquors"
                            ></v-text-field>
                            <span><strong>Comisión sugerida: </strong>{{
                                paymentDetails.guideAgencyFound.guide.guide_daily_commission_amount_liquors
                              }}%</span>
                          </td>
                          <td>${{ number_format(commissionLiquors.amount_total, 2, '.', ',') }}</td>
                          <td>$0.00</td>
                          <td>
                            ${{ number_format(getOnlyCommissionLiquors(), 2, '.', ',') }}
                          </td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </template>

                    <template v-if="commissionLiquors || commissionMerchant">
                      <v-simple-table>
                        <tbody>
                        <tr>
                          <td colspan="4" class="">
                            <p class="pa-0 mt-2 mb-0 black--text font-weight-bold">Comisión total que genera la
                              compra</p>
                            <p class="pa-0 ma-0 text-h4 black--text">${{ getFinalCommissionTotalAgency() }}</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4"><small>* Los montos son expresados en pesos mexicanos y puede cambiar sin
                            previo
                            aviso</small></td>
                        </tr>
                        <tr>
                          <td colspan="4"><small>* Algunos datos en esta tabla podrían ocultarse por motivos de
                            privacidad y
                            se requieren permisos adicionales para mostrarlos</small></td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </template>
                  </div>
                </template>
              </div>
            </div>

            <p class="text-h5 mt-5 black--text font-weight-bold">Forma de pago</p>
            <v-alert color="black" outlined dismissible>
              Por cada exhibición deberá especificar el monto y método de pago. Tenga en cuenta que si modifica el
              número
              de exhibiciones <strong>tendrá que volver a capturar los datos de pago nuevamente</strong>
            </v-alert>

            <template
                v-if="paymentDetails.numberPayments <= 0 || !proceedPaymentDetailsForm" @click="confirmNumberPayments"
            >
              <v-select
                  label="¿En cuántas exhibiciones se realizará el pago?"
                  v-model="paymentDetails.numberPayments"
                  :items="numberPayments"
              >
              </v-select>

              <v-select
                  @change="changeNumberPayments"
                  label="Seleccione la moneda para esta transacción"
                  v-model="paymentDetails.currencyTransaction"
                  item-text="currency_iso"
                  item-value="id"
                  :items="activeCurrencies"
                  return-object
              >
              </v-select>
            </template>

            <v-btn v-if="paymentDetails.numberPayments <= 0 || !proceedPaymentDetailsForm" @click="confirmNumberPayments" color="black" class="white--text" x-large>Continuar</v-btn>

            <v-btn v-if="paymentDetails.numberPayments > 0 && proceedPaymentDetailsForm" @click="changeNumberPayments" color="black" class="white--text" x-large>Cambiar forma de pago</v-btn>

            <div class="mt-6 payments-container" v-if="paymentDetails.numberPayments > 0 && proceedPaymentDetailsForm">

              <div>
                <v-alert color="black" dismissible outlined>
                  <strong>Atención: </strong> Se realizará la <strong>conversión</strong> de la moneda
                  elegida a pesos mexicanos. El sistema hará automáticamente la conversión en base a la moneda del
                  cliente
                  y solicitá el número de dólares o euros necesarios para cubrir el monto total de la compra.
                </v-alert>

                <p class="font-weight-regular black--text display-1">
                  ${{
                    `${getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)} ${paymentDetails.currencyTransaction.currency_iso}`
                  }}
                </p>

                <v-simple-table
                    v-if="paymentDetails.currencyTransaction && paymentDetails.currencyTransaction.id !== 1">
                  <thead>
                  </thead>
                  <tbody>
                  <!--                  <tr>
                                      <td>
                                        <p class="text-h6 mt-2 mb-2 light-blue&#45;&#45;text text&#45;&#45;darken-4">Desglose de totales (para otras monedas)</p>

                                        <p>
                                          <strong>1 {{ paymentDetails.currencyTransaction.currency_iso }}</strong> equivale a
                                          <strong>{{ paymentDetails.currencyTransaction.change_currency.current_amount_mxn }} pesos
                                            mexicanos</strong>
                                        </p>

                                        <p>Impuestos: {{
                                            getAmountWithConversion(cartAmounts.amountTaxes, paymentDetails.currencyTransaction)
                                          }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>
                                        <p>Subtotal: {{
                                            getAmountWithConversion(cartAmounts.totalOneAmount, paymentDetails.currencyTransaction)
                                          }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>
                                        <p>Total: {{
                                            getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)
                                          }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>

                                        <p><strong>{{ cartAmounts.totalTwoAmount }} MXN</strong> equivale a <strong>{{
                                            getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)
                                          }} {{ paymentDetails.currencyTransaction.currency_iso }}</strong></p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        <p class="text-h6 mt-2 mb-2 light-blue&#45;&#45;text text&#45;&#45;darken-4">
                                          El cliente deberá pagar <strong>{{
                                            `${getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)} ${paymentDetails.currencyTransaction.currency_iso}`
                                          }}</strong>
                                        </p>
                                      </td>
                                    </tr>-->
                  </tbody>
                </v-simple-table>
              </div>

              <v-card class="mb-5" v-for="(itemPayment, index) in paymentDetails.payments" elevation="1">
                <v-card-title>
                  Pago #{{ index + 1 }}:
                </v-card-title>
                <v-card-subtitle>
                  Especifique la forma de pago del pago #{{ index + 1 }}:
                  <span
                      @click="calculateRemainsAmount(index)"
                      v-if="paymentDetails.payments.length > 1 && index !== 0"><a href="#!">Calcular restante</a></span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader>
                        Cantidad a pagar:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">

                      <v-text-field
                          label="Cantidad a liquidar en este pago"
                          :disabled="paymentCannotBeChanged()"
                          @change="saveAmountsConvertedPaymentAmount(itemPayment, index)"
                          v-model="itemPayment.paymentAmount"
                          prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="(parseInt(itemPayment.paymentMethod) === 1)">
                    <v-col cols="4">
                      <v-subheader>
                        Cantidad en efectivo proporcionada:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="itemPayment.cashAmountProvided"
                          @keyup="calculateChangeCash()"
                          label="Dinero en efectivo"
                          prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="(parseInt(itemPayment.paymentMethod) === 1)">
                    <v-col cols="4">
                      <v-subheader>
                        Cambio en efectivo calculado en {{ paymentDetails.currencyTransaction.currency_iso }}:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          :value="itemPayment.changeCalculated"
                          @keyup="calculateChangeCash()"
                          label="Cambio"
                          prefix="$"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div
                      v-if="(parseInt(paymentDetails.currencyTransaction.id) !== 1)">
                    <v-row>
                      <v-col cols="4">
                        <v-subheader>
                          Cambio equivalente en pesos mexicanos
                        </v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="itemPayment.changeCalculatedMxn"
                            label="Cambio"
                            prefix="$"
                            disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col><small>* Este pago de
                        {{ `$${itemPayment.paymentAmount} ${paymentDetails.currencyTransaction.currency_iso}` }} al tipo
                        de cambio en MXN será transaccionado en
                        ${{ getAmountConversionToMXN(itemPayment.paymentAmount, paymentDetails.currencyTransaction) }}
                        MXN</small></v-col>
                    </v-row>
                  </div>

                  <v-radio-group
                      @change="saveAmountsConvertedPaymentAmount(itemPayment, index)"
                      v-model="itemPayment.paymentMethod"
                      row
                  >
                    <v-radio
                        :disabled="paymentDetails.cashCaptured"
                        label="Efectivo"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Tarjeta de Débito"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        label="Tarjeta de Crédito"
                        :value="3"
                    ></v-radio>
                  </v-radio-group>
                  <v-select
                      v-show="(itemPayment.paymentMethod !== 1)"
                      label="Seleccionar TPV"
                      v-model="itemPayment.tpvType"
                      :items="tpvTypesList"
                      item-text="tpv_type_name"
                      item-value="id"
                      return-object></v-select>
                  <!--                  <v-row>
                                      <v-col>
                                        <v-radio-group
                                            @change="saveAmountsConvertedPaymentAmount(itemPayment, index)"
                                            v-model="itemPayment.paymentMethod"
                                            row
                                        >
                                          <v-radio
                                              :disabled="(index!==0)"
                                              label="Efectivo"
                                              :value="1"
                                          ></v-radio>
                                          <v-radio
                                              label="Tarjeta de Débito"
                                              :value="2"
                                          ></v-radio>
                                          <v-radio
                                              label="Tarjeta de Crédito"
                                              :value="3"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>

                                      <v-col cols="auto" v-show="(itemPayment.paymentMethod !== 1)">
                                        <v-select
                                            label="Seleccionar TPV"
                                            v-model="itemPayment.tpvType"
                                            :items="tpvTypesList"
                                            item-text="tpv_type_name"
                                            item-value="id"
                                            return-object
                                        ></v-select>
                                      </v-col>
                                    </v-row>-->

                </v-card-text>
              </v-card>

              <div>
                <v-alert color="black" class="white--text">
                  Por favor especifique los totales en cada pago. Todos deben sumar la cantidad total a cobrar que es de
                  <strong>${{
                      `${getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)} ${paymentDetails.currencyTransaction.currency_iso}`
                    }}</strong>
                </v-alert>

                <v-textarea
                    counter
                    v-model="paymentDetails.orderComments"
                    :rules="rulesCommentsOrder"
                    outlined
                    name="input-7-4"
                    label="¿Desea agregar alguna noticia adicional?"
                ></v-textarea>

              </div>
            </div>
          </div>


          <div class="mt-6">
            <div v-if="loadingPaymentCart">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Procesando, espere por favor
            </div>

            <div v-if="paymentDetails.numberPayments > 0 && proceedPaymentDetailsForm">
              <div v-if="!loadingPaymentCart && !orderClosed()">
                <v-btn @click="confirmAndFinishOrder()"
                       :disabled="!canProceedPaymentConfirmation()"
                       color="black"
                       outlined
                       x-large>Confirmar y finalizar compra
                </v-btn>

                <v-btn
                    :disabled="(invoiceIdOpenedFound > 0)"
                    elevation="0"
                    class="white--text ml-3"
                    color="black"
                    @click="openOrdenCompraDialog"
                    x-large>Crear orden de compra
                </v-btn>
                <div class="pt-3" v-if="(invoiceIdOpenedFound > 0)">
                  <v-divider class="mb-3"></v-divider>
                  No se puede crear una orden de compra ya que esta orden fue abierta como una orden de compra
                  previamente. Finalice la compra ahora.
                </div>
              </div>
            </div>


            <div v-if="orderClosed() && invoiceDetailsCreated">
              <v-alert color="black" outlined>
                <strong>Atención.</strong> Favor de <strong>imprimir</strong> el recibo generado de esta orden.
                Gracias.
              </v-alert>
              <iframe style="width:100%;height:500px;" :src="getOrderTicketPdfUrl()"></iframe>
            </div>
          </div>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="finalizarCompraDialog = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Cash Closing-->
    <v-dialog v-model="dialogCashRegisterHistory" max-width="980px">
      <v-card>
        <v-btn icon @click="dialogCashRegisterHistory = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>Ajuste de fondo de caja</v-card-title>
        <v-card-text>
          <v-alert outlined dismissible color="black">
            Guarde el ajuste de fondo de caja por día para arrancar con un monto inicial la caja
          </v-alert>

          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-select label="Seleccione la caja" v-model="formCashHistory.cash_register_id" :items="listCashRegister"
                        item-text="cash_register_name" item-value="id"></v-select>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              <v-text-field v-model="formCashHistory.cash_fund_amount_mxn"
                            label="Ingrese el monto de fondo de caja en Pesos mexicanos"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              <v-text-field v-model="formCashHistory.cash_fund_amount_usd"
                            label="Ingrese el monto de fondo de caja en Dólares"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              <v-text-field v-model="formCashHistory.cash_fund_amount_eur"
                            label="Ingrese el monto de fondo de caja en Euros"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              <v-text-field type="date" v-model="formCashHistory.cash_opening_date"
                            label="Fecha de apertura"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              <v-text-field type="date" v-model="formCashHistory.cash_closing_date"
                            label="Fecha de cierre"></v-text-field>
            </v-col>
          </v-row>


          <p class="text-h5 mt-5 black--text font-weight-bold">Historial de fondos de caja</p>
          <v-data-table class="mt-3" :headers="headersCashHistory" :items="cashRegisterHistory"
                        :loading="loadingCashRegisterTable">
            <template v-slot:item.actions="{item, index}">
              <v-btn
                  icon @click="deleteCashRegisterHistory(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <v-btn :disabled="loadingStoringCashHistory" @click="storeCashRegisterHistory()" x-large color="black"
                 class="white--text mb-5">
            Confirmar ajuste de fondo de caja
          </v-btn>

          <v-divider></v-divider>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCashRegisterHistory = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Cash Funds-->
    <v-dialog v-model="dialogCashFunds" max-width="950px" persistent>
      <v-card>
        <v-btn icon @click="dialogCashFunds = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>Aportaciones de efectivo a la caja</v-card-title>
        <v-card-text>
          <v-alert outlined dismissible color="black">
            Las aportaciones que haga en la fecha que indique se tomará en cuenta para el corte de caja global
          </v-alert>

          <v-select label="Seleccione la caja" v-model="formCashFunds.cash_register_id" :items="listCashRegister"
                    item-text="cash_register_name" item-value="id"></v-select>

          <v-text-field v-model="formCashFunds.cash_contribution_date" type="date"
                        label="Fecha del movimiento"></v-text-field>

          <v-text-field v-model="formCashFunds.cash_contribution_amount" type="number"
                        label="Cantidad en efectivo de la aportación"></v-text-field>

          <v-select v-model="formCashFunds.cash_contribution_currency_id" :items="activeCurrencies" item-value="id"
                    item-text="currency_iso" label="Tipo de moneda de la aportación"></v-select>

          <v-textarea v-model="formCashFunds.cash_contribution_details"
                      label="Detalles adicionales de la aportación"></v-textarea>

          <v-btn :disabled="loadingStoringCashingFunds" @click="storeCashContribution()" x-large color="black"
                 class="white--text mb-5">
            Confirmar aportación
          </v-btn>

          <v-divider></v-divider>

          <p class="text-h5 mt-5 black--text font-weight-bold">Visitas del día</p>
          <v-data-table class="mt-3" :headers="headersContributions" :items="cashContributionsHistory"
                        :loading="loadingCashContributionsTable">
            <template v-slot:item.actions="{item, index}">
              <v-btn
                  icon @click="deleteCashFund(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCashFunds = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogAuthentication" max-width="500px" persistent>
      <v-btn icon @click="showDialogAuthentication = false" class="float-right ma-5">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card>
        <v-card-title class="headline">Se requiere contraseña para ejecutar esta acción</v-card-title>

        <v-card-text>
          <v-text-field
              type="password"
              v-model="inputPasswordAdmin"
              label="Contraseña de súper administrador"
              outlined
          ></v-text-field>

          <v-alert type="error" v-if="showAdminErrorAuth">
            Ocurrió un error al autenticar. Intentar de nuevo.
          </v-alert>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDialogAuthentication = false" text>Cerrar y cancelar</v-btn>
          <v-btn color="black" class="white--text" @click="confirmAuth">Confirmar acción</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Cash Outflows-->

    <!--Agency Pay Commissions Report-->
    <v-dialog max-width="950px" v-model="dialogPayAgencyCommissions">
      <v-btn icon @click="dialogPayAgencyCommissions = false" class="float-right ma-5">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card>
        <v-card-title>Reporte de agencia y pago de comisiones</v-card-title>
        <v-card-text>

          <v-select
              v-model="formPayAgencyCommissions.agencySelected"
              label="Seleccione agencia"
              :items="currentActiveAgencies"
              item-text="agency_business_name"
              item-value="id"
              return-object
          ></v-select>

          <v-select
              item-value="id"
              item-text="month_name"
              label="Seleccionar mes del reporte" :items="monthsList" v-model="formPayAgencyCommissions.monthReport"
              return-object></v-select>

          <v-select
              item-value="id"
              item-text="year_name"
              label="Seleccionar año del reporte" :items="yearsList" v-model="formPayAgencyCommissions.yearReport"
              return-object></v-select>


          <v-divider class="mt-3"></v-divider>

          <v-btn :disabled="loadingCommissionsPay" @click="generateReportPayCommissionAgency()" x-large
                 color="black" class="white--text mb-5">
            Generar reporte de comisiones
          </v-btn>

          <div class="mt-3 mb-3" v-if="loadingCommissionsPay">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Cargando...
          </div>

          <iframe style="width:100%;height:500px;" v-if="showIframePayCommissionAgency" :src="urlPayCommissionAgencyPdf"></iframe>

          <v-spacer></v-spacer>
          <v-btn :disabled="!showIframePayCommissionAgency" :href="urlPayCommissionAgencyExcel" x-large color="black" outlined download>
            <v-icon>mdi-file-excel-box-outline</v-icon> Descargar en Excel
          </v-btn>
          <v-btn class="ml-3" :disabled="!showIframePayCommissionAgency" :href="urlPayCommissionAgencyPdf" target="_blank" x-large color="black" outlined download>
            <v-icon>mdi-file-pdf-box</v-icon> Descargar PDF
          </v-btn>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogPayAgencyCommissions = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Reporte global de corte de caja-->
    <v-dialog max-width="950px" v-model="dialogCashClosing" persistent>

      <v-card >
        <!-- Agregar ícono de cerrar en la parte superior derecha -->
        <v-btn icon @click="dialogCashClosing = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Reporte global de caja y corte de caja
        </v-card-title>
        <v-card-text>
          <v-menu
              ref="menu"
              v-model="menuCashDate"
              :close-on-content-click="false"
              :return-value.sync="formCashFundReport.cash_fund_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="formattedDateClosingCash"
                  label="Seleccionar una fecha diferente para el corte de caja"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                :max="moment().format('YYYY-MM-DD')"
                v-model="formCashFundReport.cash_fund_date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="getGlobalReportCashByDate(formCashFundReport.cash_fund_date)"
              >
                Seleccionar
              </v-btn>
            </v-date-picker>
          </v-menu>

<!--          <p class="ma-0 pa-0 text-h6 black&#45;&#45;text">Fondo de caja</p>
          <v-simple-table>
            <tbody>
            <tr>
              <td>MXN Pesos mexicanos: </td>
              <td>$0.00</td>
            </tr>
            <tr>
              <td>USD Dólares: </td>
              <td>$0.00</td>
            </tr>
            <tr>
              <td>EUR Euros: </td>
              <td>$0.00</td>
            </tr>
            </tbody>
          </v-simple-table>-->
          <template v-if="showGlobalCashReportIframe">
            <iframe style="width:100%;height:500px;" :src="globalCashReportUrl"></iframe>

            <v-spacer></v-spacer>
            <v-btn :href="globalCashReportUrlExcel" target="_blank" x-large color="black" outlined download>
              <v-icon>mdi-file-excel-box-outline</v-icon> Descargar en Excel
            </v-btn>
            <v-btn class="ml-3" :href="globalCashReportUrl" target="_blank" x-large color="black" outlined download>
              <v-icon>mdi-file-pdf-box</v-icon> Descargar PDF
            </v-btn>

          </template>

<!--          <v-simple-table>
            <tbody>
            <tr>
              <td>Fecha: </td>
              <td>{{ formattedDateClosingCash }}</td>
            </tr>
            <tr>
              <td>USD Dólares: </td>
              <td>$0.00</td>
            </tr>
            <tr>
              <td>EUR Euros: </td>
              <td>$0.00</td>
            </tr>
            </tbody>
          </v-simple-table>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCashClosing = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="800px" v-model="dialogCashOutflow" persistent>

      <v-card>
        <!-- Agregar ícono de cerrar en la parte superior derecha -->
        <v-btn icon @click="dialogCashOutflow = false" class="float-right ma-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Registrar salida de dinero
        </v-card-title>
        <v-card-text>
          <v-alert color="black" outlined dismissible>
            Los movimientos que registre <strong>se verán reflejados en el corte de caja del día</strong>
          </v-alert>

          <v-row>

            <v-col cols="12" lg="12" md="12" sm="12">
              <v-select label="Seleccione la caja" v-model="formCashOutflow.cash_register_id" :items="listCashRegister"
                        item-text="cash_register_name" item-value="id"></v-select>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field label="Monto del movimiento" v-model="formCashOutflow.amount_outflow"></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field type="date" label="Fecha del movimiento" v-model="formCashOutflow.date_outflow"></v-text-field>
            </v-col>

            <v-col cols="12" lg="12" md="12" sm="12">
              <v-select
                  label="Seleccione la moneda para esta transacción"
                  v-model="formCashOutflow.currency_id"
                  item-text="currency_iso"
                  item-value="id"
                  :items="activeCurrencies"
              >
              </v-select>
            </v-col>

            <v-col cols="12" lg="12" md="12" sm="12">
              <v-textarea label="Descripción breve del movimiento" v-model="formCashOutflow.details_outflow"></v-textarea>
            </v-col>
          </v-row>

          <div v-if="loadingSavingCashOutflow">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Cargando...
          </div>
          <v-btn
              @click="storeCashOutflow"
              :disabled="loadingSavingCashOutflow"
              x-large
              color="black"
              class="white--text"
          >Guardar salida de dinero</v-btn>

          <v-divider class="pt-5 mt-5 mb-5"></v-divider>

          <p class="text-h5 black--text font-weight-bold mt-2 mb-2">Registro de movimientos de todos los días</p>

          <v-data-table :loading="loadingSavingCashOutflow" :headers="headersCashOutflows" :items="itemsCashOutflows">
            <template v-slot:item.actions="{item, index}">
              <v-btn @click="deleteCashOutflow(item, i)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCashOutflow = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import 'moment/locale/es';
import NumeroALetras from "@/utils/numeroALetras";
import SearchVisitTable from "@/views/views/shoppingcart/SearchVisitTable.vue";
import DetailsGuidePayVisit from "@/views/views/shoppingcart/DetailsGuidePayVisit.vue";
import ResumeGuidePay from "@/views/views/shoppingcart/ResumeGuidePay.vue";

export default {
  name: 'ShoppingCart',
  components: {ResumeGuidePay, DetailsGuidePayVisit, SearchVisitTable},

  data() {
    return {
      conceptIdScanText: '',
      dialogBarcode: false,
      globalCashReportUrl: '',
      globalCashReportUrlExcel: '',
      showGlobalCashReportIframe: false,
      itemsCashOutflows: [],
      loadingSavingCashOutflow: false,
      formCashOutflow: {
        cash_register_id: 0,
        amount_outflow: 0,
        date_outflow: '',
        details_outflow: '',
        currency_id: 0,
      },
      dialogCashOutflow: false,
      formCashFundReport: {
        cash_fund_date: '',
      },
      loadingCommissionsPay: false,
      urlPayCommissionAgencyExcel:'',
      showIframePayCommissionAgency: false,
      urlPayCommissionAgencyPdf: '',
      currentActiveAgencies: [],
      monthsList: [],
      yearsList: [],
      dialogPayAgencyCommissions: false,
      formPayAgencyCommissions: {
        agencySelected: null,
        monthReport: 0,
        yearReport: 0,
      },

      showDialogAuthentication: false,
      showAdminErrorAuth: false,
      inputPasswordAdmin: '',
      listCashRegister: [],
      loadingStoringCashHistory: false,
      formCashHistory: {
        cash_register_id: 0,
        cash_fund_amount_mxn: 0,
        cash_fund_amount_usd: 0,
        cash_fund_amount_eur: 0,
        cash_opening_date: moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD'),
        cash_closing_date: moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD'),
      },
      headersCashHistory: [
        {text: 'ID', value: 'id'},
        {text: 'Monto fondo de caja MXN', value: 'cash_fund_amount_mxn'},
        {text: 'Monto fondo de caja USD', value: 'cash_fund_amount_usd'},
        {text: 'Monto fondo de caja EUR', value: 'cash_fund_amount_eur'},
        {text: 'Fecha apertura caja', value: 'cash_opening_date'},
        {text: 'Fecha cierre caja', value: 'cash_closing_date'},
        {text: 'Acciones', value: 'actions'},
      ],
      loadingCashRegisterTable: false,
      cashRegisterHistory: [],

      loadingStoringCashingFunds: false,
      formCashFunds: {
        cash_register_id: 0,
        cash_contribution_amount: 0,
        cash_contribution_details: 'N/A',
        cash_contribution_currency_id: 0,
        cash_contribution_date: moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD'),
      },
      headersContributions: [
        {text: 'ID', value: 'id'},
        {text: 'Monto', value: 'cash_contribution_amount'},
        {text: 'Moneda', value: 'currency.currency_iso'},
        {text: 'Fecha', value: 'cash_contribution_date'},
        {text: 'Detalles adicionales', value: 'cash_contribution_details'},
        {text: 'Acciones', value: 'actions'},
      ],
      loadingCashContributionsTable: false,
      cashContributionsHistory: [],
      dialogCashClosing: false,
      dialogCashFunds: false,
      dialogCashRegisterHistory: false,
      currentRegisterVisitPay: null,
      loadingPayVisitResults: false,
      invoiceRegisterDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menuCashDate: false,
      listSaleUsers: [],
      tpvTypesList: [],
      selectedColor: null,
      colors: [],
      showCommissionsDetailsCheckout: false,
      commissionLiquors: null,
      commissionMerchant: null,
      itemsOptionsStatusVisit: [
        {
          id: 1,
          name: 'Ingresó'
        },
        {
          id: 2,
          name: 'Abandonó/Se retiró'
        }
      ],
      itemsVisitsInside: [],
      loadingRegisteringVisit: false,
      registerVisitForm: {
        driversNames: [],
        numberOfDrivers: 1,
        salesUsersVisit: [],
        manualColorText: '',
        manualCaptureColor: false,
        //numberOfSaleUsers: 1,
        saleUser: null,
        saleUserId: 0,
        //saleUsersSelected: [],
        selectedColor: null,
        guideAgency: null,
        numberPeople: 1,
        guideComeWithDriver: 0,
        additionalNotes: 'N/A',
        numberOfSaleUsers: 1,
      },
      visitAgencySelected: false,
      headersVisits: [
        //{text: 'Color', value: 'color_register_visit'},
        {text: 'Orden', value: 'order'},
        {text: 'Vendedores', value: 'sales_users_register_visit'},
        {text: 'Guía/Agencia', value: 'guide.guide_name'},
        {text: 'Viene con chófer', value: 'come_with_driver_string'},
        {text: 'Personas', value: 'number_of_persons_visit'},
        {text: 'Status', value: 'status_visit_string'},
        {text: 'Última actualización', value: 'updated_at'},
      ],
      headersCashOutflows: [
        {text: 'ID', value: 'id'},
        {text: 'Monto', value: 'amount_outflow'},
        {text: 'Fecha', value: 'date_outflow'},
        {text: 'Moneda', value: 'currency.currency_iso'},
        {text: 'Detalles', value: 'details_outflow'},
        {text: 'Acciones', value: 'actions'},
      ],
      itemsVisits: [],
      dialogRegisterVisit: false,
      searchConcepts: '',
      conceptsTabs: 0,
      merchantList: null,
      formattedDate: '',
      showGuidePayInvoiceIframe: false,
      loadingPayGuideVisit: false,
      loadingDetailsGuideVisitSales: false,
      detailsGuideVisitSales: null,
      payVisitGuide: {
        guidePayGenerated: null,
        isValidGuide: false,
        businessCode: '',
        guideAgencyFound: null
      },
      showOrderNoFoundError: false,
      loadingSearchingOrder: false,
      dialogOpenOrder: false,
      loadingOrderCreation: false,
      orderShoppingCart: {
        additionalComments: 'Sin comentarios.',
      },
      showBusinessCodeResultError: false,
      showBusinessCodeResultSuccess: false,
      searchingBusinessCode: false,
      rulesCommentsOrder: [v => v.length <= 249 || 'Máximo 249 caracteres (contando espacios)'],
      showHideOrderResume: false,
      proceedPaymentDetailsForm: false,
      numberPayments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      paymentDetails: {
        // No
        cashCaptured: false,
        guideAmountCommission: 0,
        guideAgencyFound: null,
        agencyGuideBusinessCode: '',
        isOrderByGuideAgency: 2,
        currencyTransaction: null,
        numberPayments: 0,
        payments: [],
        orderComments: 'Sin comentarios.',
      },

      ordenCompraDialog: false,
      finalizarCompraDialog: false,
      currenciesChanges: [],
      headersHistoryCurrency: [
        /*{
          text: 'Moneda',
          value: 'currency_id',
          sortable: true,
        },*/

        {
          text: 'Moneda',
          value: 'currency.currency_iso',
          sortable: true,
        },
        {
          text: 'Valor del cambio',
          value: 'current_amount_mxn',
          sortable: true,
        },
        {
          text: 'Estatus',
          value: 'is_active',
          sortable: true,
        },
        {
          text: 'Fecha del tipo de cambio',
          value: 'created_at',
          sortable: true,
        },
      ],
      historyCurrencyChanges: null,
      dialogCurrency: false,
      activeIVATax: true,
      quantitiesDefaultSelect: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      itemsCart: [],
      currentSearchItem: null,
      autocompleteSearch: null,
      loadingSearchingConcepts: false,
      searchInput: null,
      itemsConcepts: null,
      showErrorMessage: false,
      showSavedMessage: false,
      loadingStoreConcept: false,
      financialConceptForm: {
        conceptName: '',
        conceptDetails: 'N/A',
        conceptPriceMxn: 0,
        merchantId: 0,
      },
      dialogConcepts: false,
      dialogAddItemCart: false,
      cartSettings: {
        applyDiscountToOrder: false,
      },
      cartAmounts: {
        discountsAppliedMxn: 0,
        totalOneAmount: 0,
        amountTaxes: 0,
        ivaTaxPercentage: 16,
        totalTwoAmount: 0,
      },
      changesCurrencyMXN: {
        amount: 0,
      },
      changesCurrencyUSD: {
        amount: 0,
      },
      changesCurrencyEUR: {
        amount: 0,
      },
      activeCurrencies: null,
      loadingPaymentCart: false,
      orderOpenedCreated: false,
      orderFinishedVoided: false,
      invoiceDetailsCreated: null,
      invoiceOrderNumber: '',
      invoiceOrderNumberFound: '',
      invoiceIdOpenedFound: 0,
      lastFinishedOrders: [],
      lastOpenedOrders: [],
      loadingFinishedOrders: true,
      loadingOpenedOrders: true,
      dialogPagarVisita: false,
      itemsConceptsHistory: [],
      loadingConcepts: false,
      headersConcepts: [
        {text: 'ID', value: 'id'},
        {text: 'Nombre', value: 'concept_name'},
        {text: 'Detalles', value: 'concept_details'},
        {text: 'Precio', value: 'concept_price_mxn'},
        {text: 'Tipo', value: 'merchant.merchant_name'},
        {text: 'Código barra', value: 'print_barcode_concept'},
        {text: 'Fecha alta', value: 'created_at'},
      ],
      listAgenciesGuides: [],
      searchQuery: '', // Inicializa como cadena vacía
      itemsGuidesAgencies: [],
      isLoadingSearchGuides: false,

    }
  },
  created() {
    this.getHistoryCurrChanges();
    this.getCurrenciesChanges()
    this.getActiveCurrencies()
    this.getMerchantConcepts()
    this.getLastFinishedOrders()
    this.getLastOpenedOrders()
    this.getColorsRegisterVisits()
    this.getTpvTypesList()
    this.getSaleUsers()
    this.getCashRegister()
        .then((res) => {
          this.listCashRegister = res.data.data
        })
  },
  mounted() {

    this.fillMonthsList();
    this.fillYearsList();

    //this.formattedDate = moment().locale('es').tz('America/Mexico_City').format('LLLL')
    this.financialConceptForm.conceptDetails = 'N/A'


    // Run every 10 minutes refresh opened and finished orders
    setInterval(() => {
      this.getLastFinishedOrders()
      this.getLastOpenedOrders()
    }, 600000)

    this.startDateTimeUpdate();

  },
  computed: {
    formattedDateRegisterVisit() {
      if (!this.invoiceRegisterDate) return '';
      // Usamos moment.js para formatear la fecha
      return moment(this.invoiceRegisterDate).format('D [de] MMMM [de] YYYY');
    },
    formattedDateClosingCash() {
      if (!this.formCashFundReport.cash_fund_date) return '';
      // Usamos moment.js para formatear la fecha
      return moment(this.formCashFundReport.cash_fund_date).format('D [de] MMMM [de] YYYY');
    }
  },
  watch: {
    'registerVisitForm.manualCaptureColor': function(val){
      if(val) {
        this.registerVisitForm.manualColorText = ''
        this.$nextTick(() => {
          // Focus en el campo de texto
          this.$refs.manualColorInput.focus();
        });
      }
    },
    dialogBarcode(isOpen) {
      if (isOpen) {
        // Usar nextTick para asegurarse de que el modal esté completamente abierto
        this.$nextTick(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.conceptIdField.focus();
            }, 100); // Esperar 100 ms antes de aplicar el focus
          });
        });
      }
    },
    searchQuery(val) {
      if (typeof val !== 'string' || val.length < 3) {
        this.itemsGuidesAgencies = [];
        return;
      }

      if (this.isLoadingSearchGuides) return;

      this.isLoadingSearchGuides = true;

      this.$http.get(`${this.$apiUrl}/premier-customers/get-all-guides`, {
        params: {q: val}
      })
          .then((res) => {
            this.itemsGuidesAgencies = res.data.data;
            console.log(this.itemsGuidesAgencies);
          })
          .finally(() => {
            this.isLoadingSearchGuides = false;
          });
    },
    'paymentDetails.isOrderByGuideAgency': function (val, oldVal) {
      // No
      console.log(val, oldVal)
      if (val === 2) {
        this.paymentDetails.guideAgencyFound = null
        this.paymentDetails.agencyGuideBusinessCode = ''
      }
    },
    /*'paymentDetails.numberPayments': function(val, oldVal) {

    },*/
    autocompleteSearch: function (val, oldVal) {
      // Verificar si val no es null o undefined antes de continuar
      if (!val || !val.id) {
        console.warn('Valor de autocompleteSearch es nulo o no válido');
        return;
      }

      // this function is used to add item to shopping cart
      this.currentSearchItem = val;

      if (this.doesConceptExist(this.currentSearchItem.id)) {
        alert('Este concepto ya fue agregado')
        return false
      }

      if (val !== oldVal) {
        //console.log('add to shoppingcart');
        //console.log(this.currentSearchItem);

        this.itemsCart.push({
          merchant: this.currentSearchItem.merchant,
          merchant_id: this.currentSearchItem.merchant.id,
          concept_id: this.currentSearchItem.id,
          concept_name: this.currentSearchItem.concept_name,
          concept_details: this.currentSearchItem.concept_details,
          concept_price_mxn: this.currentSearchItem.concept_price_mxn,
          concept_price_to_usd: this.convertAmountToCurrency(this.currentSearchItem.concept_price_mxn, this.currenciesChanges[0]),
          concept_price_to_eur: this.convertAmountToCurrency(this.currentSearchItem.concept_price_mxn, this.currenciesChanges[1]),
          quantities: 1,
          amount_item: 0,
        });

        // Update last item shoppingcart amount
        this.updateAmountItemCart(this.itemsCart[this.itemsCart.length - 1], this.itemsCart.length - 1);

        // re order
        this.itemsCart = this.sortItemsByMerchantId()

        //console.log(JSON.stringify(this.itemsCart))

        // Clear the autocomplete input after Vue updates the DOM
        this.$nextTick(() => {
          this.autocompleteSearch = null;
          this.searchInput = '';  // Limpia el campo de búsqueda
        });
      }
    },

    searchInput: function (val, oldVal) {
      if (val && val.trim().length <= 2) {
        return false
      }

      if (this.loadingSearchingConcepts) {
        return false
      }

      this.loadingSearchingConcepts = true
      this.$http.get(`${this.$apiUrl}/financial-concepts?q=` + val)
          .then((res) => {
            this.itemsConcepts = [...res.data.data]; // Forzar reactividad
          }).finally(() => {
        this.loadingSearchingConcepts = false
      });
    }
  },
  methods: {
    registerVisitHasColorAssigned(itemSalesUserRegisterVisit) {
      return itemSalesUserRegisterVisit.manual_color_text && itemSalesUserRegisterVisit.manual_color_text.trim().length <= 0
    },
    updateNumberOfDrivers(){
      this.registerVisitForm.driversNames = []
      for(let iD = 0; iD < this.registerVisitForm.numberOfDrivers; iD++){
        this.registerVisitForm.driversNames.push('')
      }
    },
    deleteSaleUserVisit(index){
      this.registerVisitForm.salesUsersVisit.splice(index, 1)

      if(this.registerVisitForm.salesUsersVisit && this.registerVisitForm.salesUsersVisit.length > 0) {
        this.registerVisitForm.numberOfSaleUsers = this.registerVisitForm.salesUsersVisit.length
        return false
      }
      this.registerVisitForm.numberOfSaleUsers = 0
    },
    updateColorSalesUser(selectedId, index) {
      // Busca el objeto seleccionado en listSaleUsers
      console.log(selectedId)
      console.log(this.colors)
    },
    updateSalesUser(selectedId, index) {
      // Busca el objeto seleccionado en listSaleUsers
      const selectedUser = this.listSaleUsers.find(user => user.id === selectedId);

      if (selectedUser) {
        // Crea una copia del objeto seleccionado y actualiza salesUsersVisit
        this.$set(this.registerVisitForm.salesUsersVisit, index, { ...selectedUser });
      }
    },
    updateNumberSalesUsers(){
      // Cannot add more sale users than official number of sale users
      if(this.registerVisitForm.numberOfSaleUsers > this.listSaleUsers.length ){
        alert('No se pueden agregar más vendedores de los que existen')
        return false;
      }

      let firstItem = this.listSaleUsers[0]
      this.registerVisitForm.salesUsersVisit = []
      let loadDefaultOptions = []
      for(let it = 0; it < this.registerVisitForm.numberOfSaleUsers; it++){
        loadDefaultOptions.push({
          color_id: this.colors[0].id,
          sale_user_id: this.listSaleUsers[0].id,
          manual_capture_color: true,
          manual_capture_text: '',
        })
      }
      this.registerVisitForm.salesUsersVisit = loadDefaultOptions
      console.log('Updated')
      console.log(this.registerVisitForm.salesUsersVisit)
    },
    testPrint() {
      const confirmPrint = confirm('Sure to print?');
      if (confirmPrint) {
        if (window.electronAPI && typeof window.electronAPI.printTicketPDF === 'function') {
          // Llama al método expuesto por el preload script (dentro de Electron)
          const pdfUrl = 'https://api.premierartesanias.com/api/invoice-manager/generate-invoice-pdf/1'; // URL del PDF a imprimir
          window.electronAPI.printTicketPDF(pdfUrl)
              .then(() => {
                console.log('Impresión enviada correctamente');
              })
              .catch((err) => {
                console.error('Error al imprimir:', err);
              });
        } else {
          // Acción alternativa fuera de Electron
          alert('Printing is only available in the desktop app.');
        }
      }
    },

    addItemToCartScan(){

      if(this.conceptIdScanText.trim().length > 0) {
        this.$http.get(`${this.$apiUrl}/financial-concepts/get-financial-concept-id/${this.conceptIdScanText}`)
            .then((res) => {

              if(res.data.data) {
                this.currentSearchItem = res.data.data

                this.itemsCart.push({
                  merchant: this.currentSearchItem.merchant,
                  merchant_id: this.currentSearchItem.merchant.id,
                  concept_id: this.currentSearchItem.id,
                  concept_name: this.currentSearchItem.concept_name,
                  concept_details: this.currentSearchItem.concept_details,
                  concept_price_mxn: this.currentSearchItem.concept_price_mxn,
                  concept_price_to_usd: this.convertAmountToCurrency(this.currentSearchItem.concept_price_mxn, this.currenciesChanges[0]),
                  concept_price_to_eur: this.convertAmountToCurrency(this.currentSearchItem.concept_price_mxn, this.currenciesChanges[1]),
                  quantities: 1,
                  amount_item: 0,
                });

                // Update last item shoppingcart amount
                this.updateAmountItemCart(this.itemsCart[this.itemsCart.length - 1], this.itemsCart.length - 1);

                // re order
                this.itemsCart = this.sortItemsByMerchantId()

                //console.log(JSON.stringify(this.itemsCart))

                // Clear the autocomplete input after Vue updates the DOM
                this.$nextTick(() => {
                  this.autocompleteSearch = null;
                  this.searchInput = '';  // Limpia el campo de búsqueda
                });

                this.dialogBarcode = false
              }
              else {
                alert('Artículo inválido o inexistente')
              }
            }).finally(() => {
          this.loadingSearchingConcepts = false
        });
      }
    },
    onEnterPress(event){
      if (event.keyCode === 13) {
        this.addItemToCartScan()
        // Lógica adicional al presionar Enter
      }
    },
    openBarcodeDialog(){
      this.dialogBarcode = true
      this.conceptIdScanText = ''
    },
    getConceptBarcode(itemConcept){

      return `${this.$apiUrl}/reports-documents/concept-barcode/${itemConcept.id}`
    },
    convertAmountToCurrency(finalAmount = 0, currentPaymentCurrency){
      // let currentPaymentCurrency = this.paymentDetails.currencyTransaction

      if (currentPaymentCurrency.currency.currency_iso == 'MXN') {
        return parseFloat(finalAmount).toFixed(2)
      }
      return parseFloat(parseFloat(finalAmount) / currentPaymentCurrency.current_amount_mxn).toFixed(2)
    },
    getActiveAgencies() {
      return this.$http.get(`${this.$apiUrl}/premier-customers/get-active-agencies`)
    },
    fillMonthsList() {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

      this.monthsList = months.map((month, index) => ({
        id: index + 1,
        month_name: month
      }));
    },
    fillYearsList() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 5;
      const endYear = currentYear + 5;

      this.yearsList = Array.from({length: endYear - startYear + 1}, (v, i) => ({
        id: i + 1,
        year_name: (startYear + i).toString()
      }));
    },
    generateReportPayCommissionAgency() {
      if(
          !this.formPayAgencyCommissions.monthReport||
          !this.formPayAgencyCommissions.yearReport
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      this.loadingCommissionsPay = true
      this.showIframePayCommissionAgency = false
      this.urlPayCommissionAgencyPdf = ''
      this.urlPayCommissionAgencyExcel = ''
      setTimeout(() => {
        // Obtener el mes y el año seleccionados
        const selectedMonth = this.formPayAgencyCommissions.monthReport.id; // ID del mes
        const selectedYear = this.formPayAgencyCommissions.yearReport.year_name; // Nombre del año

        // Asegúrate de que el mes tenga siempre dos dígitos
        const month = String(selectedMonth).padStart(2, '0');

        // Generar la fecha de inicio y fin con moment.js
        const startDate = moment(`${selectedYear}-${month}-01`, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
        const endDate = moment(`${selectedYear}-${month}-01`, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');

        // Generar la URL
        let urlReport = `${this.$apiUrl}/reports-documents/get-commissions-report-by-day/${startDate}/${endDate}/${this.formPayAgencyCommissions.agencySelected.id}/${this.formPayAgencyCommissions.monthReport.month_name}/${this.formPayAgencyCommissions.yearReport.year_name}`;
        let urlExportExcel = `${this.$apiUrl}/reports-documents/export-excel-commissions-report-by-day/${startDate}/${endDate}/${this.formPayAgencyCommissions.agencySelected.id}/${this.formPayAgencyCommissions.monthReport.month_name}/${this.formPayAgencyCommissions.yearReport.year_name}`;

        this.loadingCommissionsPay = false
        this.showIframePayCommissionAgency = true
        this.urlPayCommissionAgencyPdf = urlReport
        this.urlPayCommissionAgencyExcel = urlExportExcel

        console.log(urlReport);
      }, 1000)
    },
    openPayCommissionsAgency() {
      this.urlPayCommissionAgencyPdf = ''
      this.showIframePayCommissionAgency = false
      this.currentActiveAgencies = []
      this.getActiveAgencies()
          .then((res) => {
            this.currentActiveAgencies = res.data.data
            this.formPayAgencyCommissions.agencySelected = this.currentActiveAgencies[0]
            this.dialogPayAgencyCommissions = true
          })
    },
    showAuthDialog() {
      this.inputPasswordAdmin = ''
      this.showDialogAuthentication = true;
    },

    // Método para confirmar la autenticación
    confirmAuth() {
      if (this.inputPasswordAdmin === 'luis123') { // Aquí debes realizar la validación adecuada
        this.showAdminErrorAuth = false;
        this.showDialogAuthentication = false;
        this.resolveAuth(true); // Resolución exitosa de la promesa
      } else {
        this.showAdminErrorAuth = true;
      }
    },
    requireAuthentication() {
      this.showAuthDialog();

      return new Promise((resolve) => {
        this.resolveAuth = resolve; // Guardamos la función de resolución para usarla en confirmAuth
      });
    },

    async deleteCashRegisterHistory(cashRegisterHistoryItem) {

      const isAuthenticated = await this.requireAuthentication();
      if (isAuthenticated) {
        // Aquí ejecutas la lógica de eliminación
        this.$http.delete(`${this.$apiUrl}/cash-register-history/${cashRegisterHistoryItem.id}`, {
          '_method': 'DELETE',
        })
            .then((res) => {
              this.loadingCashRegisterTable = true
              Promise.all([this.getCashRegisterHistory(), this.getCashRegister()])
                  .then(([cashContributions, cashRegister]) => {
                    this.cashRegisterHistory = cashContributions.data.data
                    this.listCashRegister = cashRegister.data.data

                    // Reset values
                    this.formCashHistory.cash_register_id = this.listCashRegister[0].id
                    this.formCashHistory.cash_fund_amount_mxn = 0
                    this.formCashHistory.cash_fund_amount_usd = 0
                    this.formCashHistory.cash_fund_amount_eur = 0
                    this.formCashHistory.cash_opening_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
                    this.formCashHistory.cash_closing_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')

                    this.dialogCashRegisterHistory = true
                  }).finally(() => {
                this.loadingCashRegisterTable = false
              })
            })
      } else {
        console.log('Authentication failed');
      }

      /*var confirmDelete = confirm('¿Confirma eliminar este registro?')
      if(confirmDelete){

      }*/
    },
    storeCashRegister() {
      this.listCashRegister = []
      this.$http.get(`${this.$apiUrl}/cash-register`)
          .then((res) => {
            this.listCashRegister = res.data.data
          })
    },
    getCashRegister() {
      return this.$http.get(`${this.$apiUrl}/cash-register`)
    },
    openCashRegisterHistory() {
      this.loadingCashRegisterTable = true
      this.cashRegisterHistory = []

      Promise.all([this.getCashRegisterHistory(), this.getCashRegister()])
          .then(([cashContributions, cashRegister]) => {
            this.cashRegisterHistory = cashContributions.data.data
            this.listCashRegister = cashRegister.data.data

            // Reset values
            this.formCashHistory.cash_register_id = this.listCashRegister[0].id
            this.formCashHistory.cash_fund_amount_mxn = 0
            this.formCashHistory.cash_fund_amount_usd = 0
            this.formCashHistory.cash_fund_amount_eur = 0
            this.formCashHistory.cash_opening_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
            this.formCashHistory.cash_closing_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')

            this.dialogCashRegisterHistory = true
          }).finally(() => {
        this.loadingCashRegisterTable = false
      })
    },
    getCashRegisterHistory() {
      return this.$http.get(`${this.$apiUrl}/cash-register-history`)
    },
    getCashContributions() {
      return this.$http.get(`${this.$apiUrl}/cash-contributions`)
    },
    deleteCashFund(itemCashFund) {
      /*const isAuthenticated = await this.requireAuthentication();
      if (isAuthenticated) {

      }*/
      var confirmDelete = confirm('¿Confirma eliminar este registro?')
      if(confirmDelete){
        this.$http.delete(`${this.$apiUrl}/cash-contributions/${itemCashFund.id}`, {
          '_method': 'DELETE',
        })
            .then((res) => {
              this.loadingCashContributionsTable = true
              this.getCashContributions()
                  .then((res) => {
                    this.cashContributionsHistory = res.data.data
                  }).finally(() => {
                this.loadingCashContributionsTable = false
              })
            })
      }
    },
    storeCashContribution() {
      /*const isAuthenticated = await this.requireAuthentication();
      if (isAuthenticated) {

      }*/
      if (
          (this.formCashFunds.cash_register_id <= 0)||
          (this.formCashFunds.cash_contribution_amount < 0) ||
          (this.formCashFunds.cash_contribution_currency_id <= 0) ||
          (this.formCashFunds.cash_contribution_details.trim().length <= 0) ||
          (this.formCashFunds.cash_contribution_date.trim().length <= 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      this.loadingCashContributionsTable = true
      this.loadingStoringCashingFunds = true
      this.$http.post(`${this.$apiUrl}/cash-contributions`, {
        cash_contribution_amount: this.formCashFunds.cash_contribution_amount,
        cash_contribution_details: this.formCashFunds.cash_contribution_details,
        cash_contribution_currency_id: this.formCashFunds.cash_contribution_currency_id,
        cash_contribution_date: this.formCashFunds.cash_contribution_date,
        cash_register_id: this.formCashFunds.cash_register_id
      }).then((res) => {
        if (res.data.data) {
          this.getCashContributions()
              .then((res) => {
                this.cashContributionsHistory = res.data.data
              })
        }
      }).finally(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Excelente!',
          text: 'Se registró completamente la aportación.',
        });

        this.loadingStoringCashingFunds = false
        this.loadingCashContributionsTable = false
        // Reset form
        this.formCashFunds.cash_contribution_amount = 0
        this.formCashFunds.cash_contribution_details = 'N/A'
        this.formCashFunds.cash_contribution_currency_id = this.activeCurrencies[0].id
        this.formCashFunds.cash_contribution_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
        this.formCashFunds.cash_register_id = this.listCashRegister[0].id
      })
    },

    storeCashRegisterHistory() {
      if (
          this.formCashHistory.cash_fund_amount_mxn <= 0 &&
          this.formCashHistory.cash_fund_amount_usd <= 0 &&
          this.formCashHistory.cash_fund_amount_eur <= 0
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'No todos los valores pueden ser cero',
        });
        return false
      }

      if (
          /*(this.formCashHistory.cash_fund_amount_mxn <= 0)||
          (this.formCashHistory.cash_fund_amount_usd <= 0)||*/
          (this.formCashHistory.cash_register_id <= 0) ||
          (this.formCashHistory.cash_opening_date.trim().length <= 0) ||
          (this.formCashHistory.cash_closing_date.trim().length <= 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }
      this.loadingCashContributionsTable = true
      this.loadingStoringCashHistory = true
      this.$http.post(`${this.$apiUrl}/cash-register-history`, {
        cash_register_id: this.formCashHistory.cash_register_id,
        cash_fund_amount_mxn: this.formCashHistory.cash_fund_amount_mxn,
        cash_fund_amount_usd: this.formCashHistory.cash_fund_amount_usd,
        cash_fund_amount_eur: this.formCashHistory.cash_fund_amount_eur,
        cash_opening_date: this.formCashHistory.cash_opening_date,
        cash_closing_date: this.formCashHistory.cash_closing_date,
      }).then((res) => {
        if (res.data.data) {
          Swal.fire({
            icon: 'success',
            title: '¡Excelente!',
            text: 'Se registró completamente el ajuste de fondo de caja.',
          });

          this.getCashRegisterHistory()
              .then((res) => {
                this.cashRegisterHistory = res.data.data
              })
        }
      }).finally(() => {
        this.loadingStoringCashHistory = false
        this.loadingCashRegisterTable = false
        // Reset form
        this.formCashHistory.cash_fund_amount_mxn = 0
        this.formCashHistory.cash_fund_amount_usd = 0
        this.formCashHistory.cash_fund_amount_eur = 0
        this.formCashHistory.cash_opening_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
        this.formCashHistory.cash_closing_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
      })
    },

    openDialogCashFunds() {
      this.loadingCashContributionsTable = true
      this.cashContributionsHistory = []

      Promise.all([this.getCashContributions(), this.getCashRegister()])
          .then(([cashContributions, cashRegisters]) => {
            this.getCashContributions(`${this.$apiUrl}/cash-contributions`)
                .then((res) => {
                  this.cashContributionsHistory = cashContributions.data.data
                  this.listCashRegister = cashRegisters.data.data

                  this.formCashFunds.cash_register_id = this.listCashRegister[0].id
                  this.formCashFunds.cash_contribution_amount = 0
                  this.formCashFunds.cash_contribution_details = 'N/A'
                  this.formCashFunds.cash_contribution_currency_id = this.activeCurrencies[0].id
                  this.formCashFunds.cash_contribution_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')

                  this.dialogCashFunds = true

                }).finally(() => {
              this.loadingCashContributionsTable = false
            })
          })
    },
    deleteCashOutflow(itemCashOutflow){
      let confirmDelete = confirm('¿Confirma eliminar este registro?')
      if(confirmDelete){
        this.loadingSavingCashOutflow = true
        this.itemsCashOutflows = []
        this.$http.delete(`${this.$apiUrl}/cash-outflows/${itemCashOutflow.id}`, {
          '_method': 'DELETE',
        })
            .then((res) => {
              this.getCashOutflowsByDate()
                  .then((res) => {
                    this.itemsCashOutflows = res.data.data

                  }).finally(() => {

              })
            }).finally(() => {
          this.loadingSavingCashOutflow = false
        })
      }

    },
    storeCashOutflow(){
      if(
          this.formCashOutflow.cash_register_id<=0||
          this.formCashOutflow.amount_outflow <= 0||
          this.formCashOutflow.currency_id <= 0||
          this.formCashOutflow.date_outflow.trim().length <= 0||
          this.formCashOutflow.details_outflow.trim().length <= 0
      ){
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      this.itemsCashOutflows = []
      this.loadingSavingCashOutflow = true
      this.$http.post(`${this.$apiUrl}/cash-outflows`, {
        cash_register_id: this.formCashOutflow.cash_register_id,
        amount_outflow: this.formCashOutflow.amount_outflow,
        date_outflow: this.formCashOutflow.date_outflow,
        details_outflow: this.formCashOutflow.details_outflow,
        currency_id: this.formCashOutflow.currency_id,
      }).then((res) => {
        this.getCashOutflowsByDate()
            .then((res) => {
              this.itemsCashOutflows = res.data.data
              // reset values
              this.formCashOutflow.amount_outflow = 0
              this.formCashOutflow.date_outflow = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
              this.formCashOutflow.details_outflow = 'N/A'
              this.formCashOutflow.currency_id = this.listCashRegister[0].id

            }).finally(() => {
          this.loadingSavingCashOutflow = false
        })
      })
    },
    getCashOutflowsByDate(){
      return this.$http.get(`${this.$apiUrl}/cash-outflows`)
    },
    openDialogCashOutflows() {
      this.itemsCashOutflows = []
      this.dialogCashOutflow = true
      this.loadingSavingCashOutflow = false
      this.formCashOutflow.currency_id = this.activeCurrencies[0].id
      this.formCashOutflow.amount_outflow = 0
      this.formCashOutflow.cash_register_id = this.listCashRegister[0].id
      this.formCashOutflow.date_outflow = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
      this.formCashOutflow.details_outflow = 'N/A'
      this.getCashOutflowsByDate()
          .then((res) => {
            this.itemsCashOutflows = res.data.data

          }).finally(() => {

      })


    },
    numberToLettersMxn(numberToConvert = 0) {
      if (!numberToConvert || numberToConvert <= 0) {
        return ''
      }
      return NumeroALetras(numberToConvert);
    },
    getSaleUsersRegisterVisitNames(salesUsersRegisterVisit) {
      let salesNames = ''
      if (salesUsersRegisterVisit && salesUsersRegisterVisit.length > 0) {

      }
    },
    changeSaleUserToRegisterVisit(itemSaleUser, index) {
      this.registerVisitForm.saleUsersSelected[index] = {
        sales_user_id: itemSaleUser.id,
        color_id: this.colors[0].id
      }
    },
    addSaleUserToRegisterVisit() {
      if (this.registerVisitForm.numberOfSaleUsers > 0) {
        this.registerVisitForm.saleUsersSelected = []
        for (let i = 1; i <= this.registerVisitForm.numberOfSaleUsers; i++) {
          this.registerVisitForm.saleUsersSelected.push({
            sales_user_id: this.listSaleUsers[0].id,
            color_id: this.colors[0].id
          })
        }
      }

    },
    // sale-users
    getSaleUsers() {
      this.$http.get(`${this.$apiUrl}/sale-users`)
          .then((res) => {
            this.listSaleUsers = res.data.data
          }).finally(() => {
      })
    },
    getColorsRegisterVisits() {
      this.colors = []
      this.$http.get(`${this.$apiUrl}/color-register-visits`)
          .then((res) => {
            this.colors = res.data.data
          }).finally(() => {
      })
    },
    getFinalCommissionTotalAgency() {
      let getAmountCommissionLiquors = this.getAmountCommissionLiquors()
      let getAmountCommissionMerchant = this.getAmountCommissionMerchant()

      return this.number_format((getAmountCommissionLiquors.amount_commission + getAmountCommissionMerchant.amount_commission).toFixed(2), 2, '.', ',')
    },
    getCalculateFinalVisitCommission(itemGuideAgency) {
      let totalComission = parseFloat(itemGuideAgency.guide.guide_commission_visit_amount)
      if (itemGuideAgency.guide_come_with_driver !== 0) {
        // include driver comission 100
        totalComission += 100
      }

      return this.number_format(parseFloat(totalComission).toFixed(2), 2, '.', ',')
    },
    getAmountCommission() {
      let currentGuideAgency = this.paymentDetails.guideAgencyFound.guide

      this.paymentDetails.guideAmountCommission = parseFloat(parseFloat(this.cartAmounts.totalTwoAmount) * (currentGuideAgency.guide_daily_commission_amount / 100)).toFixed(2)
      return this.paymentDetails.guideAmountCommission
    },
    getOnlyCommissionMerchant() {
      const {amount_commission, amount_total} = this.getAmountCommissionMerchant()
      return amount_commission
    },
    getOnlyCommissionLiquors() {
      const {amount_commission, amount_total} = this.getAmountCommissionLiquors()
      return amount_commission
    },
    getOnlyAmountMerchant() {
      const {amount_commission, amount_total} = this.getAmountCommissionMerchant()
      return amount_total
    },
    getOnlyAmountLiquors() {
      const {amount_commission, amount_total} = this.getAmountCommissionLiquors()
      return amount_total
    },
    getAmountMerchantToCurrency(){
      let currentPaymentCurrency = this.paymentDetails.currencyTransaction

      const {amountLiquors, amountMerchant} = this.getSeparatedMerchantAmounts()
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return parseFloat(amountMerchant).toFixed(2)
      }
      return parseFloat(parseFloat(amountMerchant) / currentPaymentCurrency.change_currency.current_amount_mxn).toFixed(2)
      //return this.convertAmountToCurrency(amountMerchant, this.paymentDetails.currencyTransaction)
    },
    getAmountLiquorsToCurrency(){
      let currentPaymentCurrency = this.paymentDetails.currencyTransaction

      const {amountLiquors, amountMerchant} = this.getSeparatedMerchantAmounts()
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return parseFloat(amountLiquors).toFixed(2)
      }
      return parseFloat(parseFloat(amountLiquors) / currentPaymentCurrency.change_currency.current_amount_mxn).toFixed(2)
      //return this.convertAmountToCurrency(amountLiquors, this.paymentDetails.currencyTransaction)
    },
    getAmountsMerchantLiquors() {
      let amountLiquors = 0, amountMerchant = 0;

      const {items_merchant, items_liquors} = this.categorizeItems(this.itemsCart)

      if (items_merchant && items_merchant.length > 0) {
        items_merchant.forEach((item, index) => {
          amountMerchant += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
      }

      if (items_liquors && items_liquors.length > 0) {
        items_liquors.forEach((item, index) => {
          amountLiquors += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
      }
      return {
        amount_merchant: amountMerchant,
        amount_liquors: amountLiquors,
      }
    },
    getSeparatedMerchantAmounts(){
      let amountLiquors = 0, amountMerchant = 0
      const {items_merchant: itemsMerchant, items_liquors: itemsLiquors} = this.categorizeItems(this.itemsCart)

      if (!itemsLiquors || itemsLiquors.length <= 0) {
        console.log('no items found')
        return {
          amountLiquors: amountLiquors,
          amountMerchant: amountMerchant
        }
      }

      if (itemsLiquors && itemsLiquors.length > 0) {
        itemsLiquors.forEach((item, index) => {
          amountLiquors += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
      }

      if (itemsMerchant && itemsMerchant.length > 0) {
        itemsMerchant.forEach((item, index) => {
          amountMerchant += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
      }
      return {
        amountLiquors: amountLiquors,
        amountMerchant: amountMerchant
      }
    },
    getAmountCommissionLiquors() {
      /*finalCommissionMerchant
      finalCommissionLiquors*/
      let amountCommission = 0,
          amountLiquors = 0

      if (!this.paymentDetails.guideAgencyFound) {
        console.log('no guide found')
        return {
          amount_commission: amountCommission,
          amount_total: amountLiquors
        }
      }

      let currentGuideAgency = this.paymentDetails.guideAgencyFound
      // Percentage
      //let guide_daily_commission_amount_liquors = parseFloat(currentGuideAgency.guide.guide_daily_commission_amount_liquors)
      let guide_daily_commission_amount_liquors = parseFloat(currentGuideAgency.guide.finalCommissionLiquors)

      const {items_merchant: itemsMerchant, items_liquors: itemsLiquors} = this.categorizeItems(this.itemsCart)


      if (!itemsLiquors || itemsLiquors.length <= 0) {
        console.log('no items found')
        return {
          amount_commission: amountCommission,
          amount_total: amountLiquors
        }
      }

      if (itemsLiquors && itemsLiquors.length > 0) {
        itemsLiquors.forEach((item, index) => {
          amountLiquors += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
        amountCommission = parseFloat(amountLiquors * (guide_daily_commission_amount_liquors / 100))

      }
      return {
        amount_commission: amountCommission,
        amount_total: amountLiquors
      }
    },
    getAmountCommissionMerchant() {

      let amountCommission = 0,
          amountMerchant = 0

      if (!this.paymentDetails.guideAgencyFound) {
        console.log('no guide found')
        return {
          amount_commission: amountCommission,
          amount_total: amountMerchant
        }
      }

      let currentGuideAgency = this.paymentDetails.guideAgencyFound
      // Percentage
      let guide_daily_commission_amount_merchant = parseFloat(currentGuideAgency.guide.finalCommissionMerchant)

      const {items_merchant: itemsMerchant, items_liquors: itemsLiquors} = this.categorizeItems(this.itemsCart)

      if (!itemsMerchant || itemsMerchant.length <= 0) {
        console.log('no items found')
        return {
          amount_commission: amountCommission,
          amount_total: amountMerchant
        }
      }

      if (itemsMerchant && itemsMerchant.length > 0) {
        itemsMerchant.forEach((item, index) => {
          amountMerchant += parseInt(item.quantities) * parseFloat(item.concept_price_mxn)
        })
        // Applying discounts to amount
        amountMerchant = amountMerchant - parseFloat(this.cartAmounts.discountsAppliedMxn)

        amountCommission = parseFloat(amountMerchant * (guide_daily_commission_amount_merchant / 100))

      }

      return {
        amount_commission: amountCommission,
        amount_total: amountMerchant
      }
    },
    resetSelectGuideAgencyCommission() {
      if (confirm('¿Está seguro de remover el vínculo de comisión para este guía/agencia?')) {
        this.paymentDetails.guideAgencyFound = null
        this.paymentDetails.isOrderByGuideAgency = 2
        this.showCommissionsDetailsCheckout = false
      }

    },
    selectGuideAgencyPay(itemGuideAgency) {
      this.isValidGuide = true
      this.payVisitGuide.guideAgencyFound = itemGuideAgency
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = true
    },
    selectRegisterVisitPay(itemRegisterVisit) {
      this.isValidGuide = true
      // Volvemos a hacer una consulta para traer acumulados
      this.currentRegisterVisitPay = itemRegisterVisit
      this.detailsGuideVisitSales = null
    },
    selectGuideAgency(itemGuideAgency) {
      this.paymentDetails.guideAgencyFound = itemGuideAgency

      this.showCommissionsDetailsCheckout = true
      this.commissionLiquors = this.getAmountCommissionLiquors()
      this.commissionMerchant = this.getAmountCommissionMerchant()
      this.showCommissionsDetailsCheckout = false
    },
    linkGuideAgencyToOrder(itemGuideAgency) {
      let currentGuide = itemGuideAgency
      this.paymentDetails.guideAgencyFound = itemGuideAgency

      // Usar Vue.set para hacer las propiedades reactivas
      this.$set(this.paymentDetails.guideAgencyFound.guide, 'finalCommissionMerchant', currentGuide.guide.guide_daily_commission_amount);
      this.$set(this.paymentDetails.guideAgencyFound.guide, 'finalCommissionLiquors', currentGuide.guide.guide_daily_commission_amount_liquors);


      this.showCommissionsDetailsCheckout = true
      this.commissionLiquors = this.getAmountCommissionLiquors()
      this.commissionMerchant = this.getAmountCommissionMerchant()
      this.showCommissionsDetailsCheckout = false
    },
    sortItemsByMerchantId() {
      // Clonar la lista original para no modificar directamente `itemsCart`
      let sortedItems = [...this.itemsCart];

      // Ordenar la lista usando el merchant.id
      sortedItems.sort((a, b) => {
        return a.merchant.id - b.merchant.id;
      });

      // Retornar la lista ordenada
      return sortedItems;
    },
    doesConceptExist(conceptId) {
      // Utiliza el método find para buscar el concepto por su id
      const foundItem = this.itemsCart.find(item => item.concept_id === conceptId);

      // Retorna true si el concepto existe, de lo contrario, false
      return !!foundItem;
    },
    resetSelectRegisterVisit() {
      this.registerVisitForm.guideAgency = null
      this.currentRegisterVisitPay = null
    },
    updateStatusVisit(item) {
      this.loadingRegisteringVisit = true
      this.$http.post(`${this.$apiUrl}/register-agency-guide-visits/update-status-visit`, {
        visit_id: item.id,
        status_visit_id: item.status_visit_id
      }).then((res) => {
        this.loadingRegisteringVisit = false
      })
    },
    storeRegisterVisit() {
      // this.registerVisitForm.salesUsersVisit
      // this.registerVisitForm.numberOfDrivers
      // this.registerVisitForm.driversNames
      // this.registerVisitForm.numberOfSaleUsers

      console.log('registerVisitForm.numberOfSaleUsers----->')
      console.log(this.registerVisitForm.numberOfSaleUsers)
      console.log('this.registerVisitForm.salesUsersVisit--->')
      console.log(this.registerVisitForm.salesUsersVisit)
      console.log('this.registerVisitForm.numberOfDrivers--->')
      console.log(this.registerVisitForm.numberOfDrivers)
      console.log('this.registerVisitForm.driversNames--->')
      console.log(this.registerVisitForm.driversNames)

      console.log('this.registerVisitForm.guideAgency')
      console.log(this.registerVisitForm.guideAgency)
      console.log('this.registerVisitForm.selectedColor')
      console.log(this.registerVisitForm.selectedColor)
      console.log('this.registerVisitForm.numberPeople')
      console.log(this.registerVisitForm.numberPeople)
      console.log('this.registerVisitForm.additionalNotes')
      console.log(this.registerVisitForm.additionalNotes)
      console.log('this.registerVisitForm.numberOfSaleUsers')
      console.log(this.registerVisitForm.numberOfSaleUsers)
      console.log('this.registerVisitForm.saleUserId')
      console.log(this.registerVisitForm.saleUserId)

      console.log('this.registerVisitForm.manualCaptureColor')
      console.log(this.registerVisitForm.manualCaptureColor)
      // si esta activo el capturar manualmente el color hay que validar que no este vacio y de ser vacio retornar error
      if(this.registerVisitForm.manualCaptureColor){
      }



      if (
          ((!this.registerVisitForm.numberOfDrivers) || parseInt(this.registerVisitForm.numberOfDrivers) <= 0)||
          (parseInt(this.registerVisitForm.numberOfSaleUsers) <= 0)||
          // si esta activo el capturar manualmente el color hay que validar que no este vacio y de ser vacio retornar error
          (this.registerVisitForm.manualCaptureColor && this.registerVisitForm.manualColorText.trim().length<=0)||
          (!this.registerVisitForm.guideAgency) ||
          //(!this.registerVisitForm.manualCaptureColor && !this.registerVisitForm.selectedColor) ||
          (parseInt(this.registerVisitForm.numberPeople) <= 0) ||
          (this.registerVisitForm.additionalNotes.trim().length <= 0)
          //(this.registerVisitForm.numberOfSaleUsers <= 0) ||
          //(!this.registerVisitForm.saleUsersSelected || this.registerVisitForm.saleUsersSelected.length <= 0)
          //(this.registerVisitForm.saleUserId <= 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });
        return false
      }

      // Validar al numero de vendedores
      if(this.registerVisitForm.salesUsersVisit && this.registerVisitForm.salesUsersVisit.length > 0) {
        this.registerVisitForm.salesUsersVisit.forEach((item, index) => {
          if(
              (!item || parseInt(item.id) <= 0)||
              (!item || parseInt(item.color_id) <= 0)
          ){
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'Debe especificar a los vedendores',
            });
            return false
          }
        })
      }

      this.loadingRegisteringVisit = true
      this.$http.post(`${this.$apiUrl}/register-agency-guide-visits`, {
        number_of_sale_users: this.registerVisitForm.numberOfSaleUsers,
        sales_users_list: this.registerVisitForm.salesUsersVisit,
        driver_names_list: this.registerVisitForm.driversNames,
        number_of_drivers: this.registerVisitForm.numberOfDrivers,
        set_manual_color: (this.registerVisitForm.manualCaptureColor) ? 1 : 0,
        color_manual_text: (this.registerVisitForm.manualCaptureColor) ? this.registerVisitForm.manualColorText.trim() : '',
        guide_id: this.registerVisitForm.guideAgency.id,
        number_people: this.registerVisitForm.numberPeople,
        additional_notes: this.registerVisitForm.additionalNotes,
        guide_come_with_driver: this.registerVisitForm.guideComeWithDriver,
        color_identifier_id: (this.registerVisitForm.manualCaptureColor) ? 0 : this.registerVisitForm.selectedColor.id,
        //sales_users_selected: this.registerVisitForm.saleUsersSelected
        sale_user_id: this.registerVisitForm.saleUserId
      }).then((res) => {
        if (res.data.success) {

          Swal.fire({
            icon: 'success',
            title: '¡Excelente!',
            text: 'Se registró correctamente la visita.',
          });

          this.getGuidesAgenciesVisits()

          // Reset form defaults
          this.registerVisitForm.numberOfSaleUsers = 0
          this.registerVisitForm.salesUsersVisit = []
          this.registerVisitForm.driversNames = []
          this.registerVisitForm.number_of_drivers = 0
          this.registerVisitForm.guideAgency = null
          this.registerVisitForm.numberPeople = 0
          this.registerVisitForm.guideComeWithDriver = 0
          this.registerVisitForm.additionalNotes = 'N/A'
          this.registerVisitForm.selectedColor = this.colors[0]
          this.registerVisitForm.numberOfSaleUsers = 0
          this.registerVisitForm.manualColorText = ''
          this.registerVisitForm.manualCaptureColor = ''
          // this.registerVisitForm.saleUsersSelected = []
          this.registerVisitForm.saleUserId = 0
        }
      }).catch((e) => {
      }).finally(() => {
        this.loadingRegisteringVisit = false
      })
    },
    openDialogRegisterVisit() {
      // put by default first sale user object
      let firstItem = this.listSaleUsers[0]
      this.registerVisitForm.salesUsersVisit = [{
        color_id: this.colors[0].id,
        sale_user_id: this.listSaleUsers[0].id,
        manual_capture_color: true,
        manual_capture_text: '',
      }]
      // Default 1 driver
      this.registerVisitForm.driversNames = ['']
      this.registerVisitForm.numberOfDrivers = 1

      this.itemsGuidesAgencies = []
      this.dialogRegisterVisit = true
      //this.getGuidesAgencies()
      this.registerVisitForm.selectedColor = this.colors[0]

      /*this.registerVisitForm.saleUsersSelected = []
      this.registerVisitForm.saleUsersSelected.push({
        sales_user_id: this.listSaleUsers[0].id,
        color_id: this.colors[0].id
      })*/
      this.registerVisitForm.saleUserId = 0
      this.getGuidesAgenciesVisits()
    },
    getCurrentVisits() {
      this.itemsVisitsInside = []
      this.$http.get(`${this.$apiUrl}/register-agency-guide-visits?status-visit=1`)
          .then((res) => {
            this.itemsVisitsInside = res.data.data
          }).finally(() => {
      })
    },
    openDialogCashClosing() {
      this.dialogCashClosing = true
      this.formCashFundReport.cash_fund_date = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')

      this.showGlobalCashReportIframe = false
      //this.globalCashReportUrl = ''
    },
    getGlobalCashReportUrl(){
      this.globalCashReportUrl = `${this.$apiUrl}/reports-documents/get-global-report-cash-by-day/${this.formCashFundReport.cash_fund_date}`;

      return this.globalCashReportUrl
    },
    getGlobalCashReportUrlExcel(){
      this.globalCashReportUrlExcel = `${this.$apiUrl}/reports-documents/get-global-report-cash-by-day-excel/${this.formCashFundReport.cash_fund_date}`;

      return this.globalCashReportUrlExcel
    },
    getGlobalReportCashByDate(reportDate = ''){
      console.log(reportDate)
      this.$refs.menu.save(reportDate)

      this.showGlobalCashReportIframe = true
      this.getGlobalCashReportUrl()
      this.getGlobalCashReportUrlExcel()
    },
    getCurrentVisitsByDate(paramInvoiceRegisterDate = '') {
      // let currentDate = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')
      if (paramInvoiceRegisterDate.trim().length > 0) {
        this.invoiceRegisterDate = paramInvoiceRegisterDate
        this.$refs.menu.save(paramInvoiceRegisterDate)
      }

      this.currentRegisterVisitPay = null
      this.loadingPayVisitResults = true
      this.itemsVisitsInside = []
      this.$http.get(`${this.$apiUrl}/register-agency-guide-visits?invoice-register-date=${this.invoiceRegisterDate}`)
          .then((res) => {
            this.itemsVisitsInside = res.data.data
          }).finally(() => {
        this.loadingPayVisitResults = false
      })
    },
    getGuidesAgenciesVisits() {
      this.loadingRegisteringVisit = true
      this.$http.get(`${this.$apiUrl}/register-agency-guide-visits`)
          .then((res) => {
            this.itemsVisits = res.data.data
          }).finally(() => {
        this.loadingRegisteringVisit = false
      })
    },
    /*getGuidesAgencies() {
      if (this.searchQuery.length < 3) {
        this.itemsGuidesAgencies = [];
        return;
      }

      this.isLoadingSearchGuides = true;

      this.$http.get(`${this.$apiUrl}/premier-customers/get-all-guides`, {
        params: { q: this.searchQuery }
      })
          .then((res) => {
            this.itemsGuidesAgencies = res.data.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },*/
    formatFriendlyDate(dateString) {
      // Parse the date string with moment
      return moment(dateString)
          .tz('America/Mexico_City') // Cambia esta zona horaria por la que desees
          .format('MMMM D, YYYY HH:mm'); // Formato: "Septiembre 26, 2024 17:23"
    },
    openDialogConcepts() {
      this.dialogConcepts = true
      this.getFinancialConceptsHistory()

    },
    getFinancialConceptsHistory() {
      this.loadingConcepts = true
      this.itemsConceptsHistory = []
      this.$http.get(`${this.$apiUrl}/financial-concepts`)
          .then((res) => {
            this.itemsConceptsHistory = res.data.data; // Forzar reactividad
          }).finally(() => {
        this.loadingConcepts = false
      });
    },
    startDateTimeUpdate() {
      setInterval(() => {
        // Actualizar la variable reactiva cada segundo con formato de 12 horas
        this.formattedDate = moment().locale('es').tz('America/Mexico_City').format('LLLL, h:mm A');

        //console.log(this.formattedDate);
      }, 1000);
    },
    paymentCannotBeChanged() {
      return (this.paymentDetails.payments.length === 1 && this.paymentDetails.payments[0].paymentMethod !== 1)
    },
    saveAmountsConvertedPaymentAmount(itemPayment, index) {
      this.paymentDetails.payments[index]['tpvType'] = null

      this.paymentDetails.cashCaptured = true
      // 1 MEANS CASH - EFECTIVO
      if (itemPayment.paymentMethod !== 1) {
        this.paymentDetails.cashCaptured = false
        this.paymentDetails.payments[index]['tpvType'] = this.tpvTypesList[0]
      }

      this.paymentDetails.payments[index]['payment_amount_converted'] = this.getAmountConversionToMXN(itemPayment.paymentAmount, this.paymentDetails.currencyTransaction)
      console.log(JSON.stringify(this.paymentDetails.payments))
    },
    getLastFinishedOrders() {
      this.loadingFinishedOrders = true
      // premier-customers/get-last-finished-orders
      this.$http.get(`${this.$apiUrl}/premier-customers/get-last-finished-orders`)
          .then((res) => {
            this.lastFinishedOrders = res.data.data
          }).catch((e) => {
      })
          .finally(() => {
            this.loadingFinishedOrders = false
          })
    },
    getLastOpenedOrders() {
      this.loadingOpenedOrders = true
      // premier-customers/get-last-opened-orders
      this.$http.get(`${this.$apiUrl}/premier-customers/get-last-opened-orders`)
          .then((res) => {
            this.lastOpenedOrders = res.data.data
          }).catch((e) => {
      })
          .finally(() => {
            this.loadingOpenedOrders = false
          })
    },
    hideSensitiveString(string, length = 5) {
      return `${string.substring(3, length + 2)}******`
    },

    categorizeItems(itemList) {
      let itemsMerchant = [];
      let itemsLiquors = [];

      itemList.forEach(item => {
        if (item.merchant.id === 1) {
          itemsMerchant.push(item);
        } else if (item.merchant.id === 2) {
          itemsLiquors.push(item);
        }
      });

      return {
        items_merchant: itemsMerchant,
        items_liquors: itemsLiquors
      };
    },
    getGuidePayUrlInvoice() {
      return `${this.$apiUrl}/invoice-manager/generate-guide-pay-pdf/${this.currentRegisterVisitPay.guide_pay_visit.id}`
    },
    resetGuidePay() {
      this.getCurrentVisitsByDate()
      this.currentRegisterVisitPay = null
      this.showGuidePayInvoiceIframe = false
      this.payVisitGuide.guidePayGenerated = null
      this.payVisitGuide.isValidGuide = false
      this.payVisitGuide.businessCode = ''
      this.payVisitGuide.guideAgencyFound = null
    },
    getDetailsGuideVisitSales(registerVisitId = 0) {
      this.loadingDetailsGuideVisitSales = true
      this.detailsGuideVisitSales = null
      this.$http.get(`${this.$apiUrl}/register-agency-guide-visits/get-details-guide-visit-sales/${registerVisitId}`)
          .then((res) => {
            this.detailsGuideVisitSales = res.data.data
          }).finally(() => {
        this.loadingDetailsGuideVisitSales = false
      })
    },
    openPagarVisitaModal() {
      let currentDate = moment().tz('America/Mexico_City').locale('es').format('YYYY-MM-DD')

      this.invoiceRegisterDate = currentDate
      this.currentRegisterVisitPay = null
      this.getCurrentVisitsByDate()

      this.showGuidePayInvoiceIframe = false
      this.payVisitGuide.guidePayGenerated = null
      this.payVisitGuide.isValidGuide = false
      this.payVisitGuide.businessCode = ''
      this.payVisitGuide.guideAgencyFound = null
      this.dialogPagarVisita = true
    },
    async printInvoicePayVisitCommissions() {
      this.showGuidePayInvoiceIframe = true
      let pdfInvoice = `${this.$apiUrl}/invoice-manager/generate-guide-pay-pdf/${this.currentRegisterVisitPay.guide_pay_visit.id}`
      const result = await this.$printPDF(pdfInvoice);
      if (result.success) {
        console.log('PDF enviado correctamente a la impresora.');
      } else {
        console.error('Error al imprimir PDF:', result.error);
      }
    },
    confirmPayGuideVisitAll() {
      // this.loadingPayGuideVisit = true
      this.$http.post(`${this.$apiUrl}/cart-payments/pay-guide-visit-all`, {
        'guide_commission_details': this.currentRegisterVisitPay
      }).then((res) => {
        if (res.data.success) {
          // this.currentRegisterVisitPay = null
          this.payVisitGuide.guidePayGenerated = res.data.data
          this.$set(this.currentRegisterVisitPay, 'guide_pay_visit', res.data.data);
          // this.showGuidePayInvoiceIframe = true
          this.printInvoicePayVisitCommissions()
          // this.payVisitGuide.guidePayGenerated = res.data.data
          // this.showGuidePayInvoiceIframe = true
        }
      }).catch((e) => {
      }).finally(() => {
        // this.loadingPayGuideVisit = false
      })
    },
    confirmPayGuideVisit() {
      // store
      this.loadingPayGuideVisit = true
      this.$http.post(`${this.$apiUrl}/cart-payments/pay-guide-visit`, {
        guide: this.payVisitGuide.guideAgencyFound
      }).then((res) => {
        if (res.data.success) {
          this.payVisitGuide.guidePayGenerated = res.data.data
          this.showGuidePayInvoiceIframe = true
        }
      }).catch((e) => {
      }).finally(() => {
        this.loadingPayGuideVisit = false
      })
    },
    openOpenOrderModal() {
      this.showOrderNoFoundError = false
      this.invoiceOrderNumberFound = ''
      this.invoiceOrderNumber = ''
      this.invoiceIdOpenedFound = 0
      this.dialogOpenOrder = true
    },
    searchOrderShoppingCart() {

      if (this.invoiceOrderNumber.trim().length <= 0) {
        alert('Orden inválida')
        return false
      }

      this.invoiceOrderNumberFound = ''
      this.invoiceIdOpenedFound = 0
      this.loadingSearchingOrder = true
      this.showOrderNoFoundError = false


      this.$http.post(`${this.$apiUrl}/cart-payments/search-opened-order`, {
        'invoice_order_number': this.invoiceOrderNumber,
      }).then((res) => {
        // console.log(JSON.stringify(res.data))
        if (res.data.success) {
          // order found
          let orderFound = res.data.data
          this.invoiceIdOpenedFound = orderFound.id
          this.invoiceOrderNumberFound = this.invoiceOrderNumber
          this.itemsCart = []
          for (let ic = 0; ic < orderFound.invoice_concepts.length; ic++) {
            this.itemsCart.push({
              concept_id: orderFound.invoice_concepts[ic].financial_concept_id,
              concept_name: orderFound.invoice_concepts[ic].concept_manual_description,
              concept_details: 'N/A',
              concept_price_mxn: orderFound.invoice_concepts[ic].concept_item_amount_original,
              concept_price_to_usd: this.convertAmountToCurrency(orderFound.invoice_concepts[ic].concept_item_amount_original, this.currenciesChanges[0]),
              concept_price_to_eur: this.convertAmountToCurrency(orderFound.invoice_concepts[ic].concept_item_amount_original, this.currenciesChanges[1]),
              quantities: orderFound.invoice_concepts[ic].concept_quantities,
              amount_item: orderFound.invoice_concepts[ic].concept_total_amount_original,
              merchant: orderFound.invoice_concepts[ic].financial_concept.merchant,
              merchant_id: orderFound.invoice_concepts[ic].financial_concept.merchant.id
            })
          }

          this.calculateAmounts()
          // close modal
          this.dialogOpenOrder = false

        } else {
          this.showOrderNoFoundError = true
        }
      }).finally(() => {
        this.loadingSearchingOrder = false
      })
    },
    cleanShoppingCart() {
      this.paymentDetails.guideAgencyFound = null
      this.paymentDetails.agencyGuideBusinessCode = ''
      this.paymentDetails.isOrderByGuideAgency = 2
      this.cartAmounts = {
        totalOneAmount: 0,
        amountTaxes: 0,
        ivaTaxPercentage: 16,
        totalTwoAmount: 0,
      }
      this.invoiceOrderNumber = ''
      this.invoiceOrderNumberFound = ''
      this.invoiceIdOpenedFound = 0
      this.itemsConcepts = []
      this.itemsCart = []
      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = false
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.showHideOrderResume = false
      this.finalizarCompraDialog = true
      this.proceedPaymentDetailsForm = false

      //this.paymentDetails.cashAmountProvided = 0
      //this.paymentDetails.changeCalculated = 0
      this.paymentDetails.numberPayments = 1

      // default currency
      this.paymentDetails.currencyTransaction = this.activeCurrencies[0]

    },
    getOrderTicketPdfUrl() {
      if (this.orderOpenedCreated) {
        return `${this.$apiUrl}/invoice-manager/generate-invoice-order-pdf/${this.invoiceDetailsCreated.id}`
      }
      return `${this.$apiUrl}/invoice-manager/generate-invoice-pdf/${this.invoiceDetailsCreated.id}`
    },
    orderClosed() {
      return (this.orderOpenedCreated || this.orderFinishedVoided)
    },
    getCashChangeConverted() {

      this.paymentDetails.payments[0].changeCalculatedMxn = parseFloat(parseFloat(this.paymentDetails.payments[0].changeCalculated) * parseFloat(this.paymentDetails.currencyTransaction.change_currency.current_amount_mxn)).toFixed(2)
    },
    calculateChangeCash() {

      // calculate cash payment method amount total
      let totalAmountSpecifiedCash = 0,
          cashAmountProvided = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        // 1 = Efectivo / Cash
        if (parseInt(this.paymentDetails.payments[pm].paymentMethod) === 1) {
          cashAmountProvided = parseFloat(this.paymentDetails.payments[pm].cashAmountProvided).toFixed(2)
          totalAmountSpecifiedCash = parseFloat(this.paymentDetails.payments[pm].paymentAmount).toFixed(2)
        }
      }
      // difference cash change
      let cashChangeCalculated = parseFloat(cashAmountProvided - totalAmountSpecifiedCash).toFixed(2)
      // set change calculated to item cash type
      this.paymentDetails.payments[0]['changeCalculated'] = (cashChangeCalculated <= 0) ? 0 : cashChangeCalculated
      // save converted amount provided
      // we send 0 because 0 is the first payment in cash
      this.saveAmountsConvertedPaymentAmount(this.paymentDetails.payments[0], 0)


      // calculating change in pesos mexicanos
      this.getCashChangeConverted()
    },
    searchBusinessCodePayVisit() {
      if (this.payVisitGuide.businessCode.trim().length <= 0) {
        alert('Código inválido')
        return false
      }
      this.isValidGuide = false
      this.payVisitGuide.guideAgencyFound = null
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.searchingBusinessCode = true
      this.$http.post(`${this.$apiUrl}/premier-customers/search-business-code`, {
        business_code: this.payVisitGuide.businessCode
      }).then((res) => {
        if (res.data.success) {
          this.isValidGuide = true
          this.payVisitGuide.guideAgencyFound = res.data.data
          this.showBusinessCodeResultError = false
          this.showBusinessCodeResultSuccess = true
        } else {
          this.showBusinessCodeResultError = true
          this.showBusinessCodeResultSuccess = false
          this.payVisitGuide.businessCode = ''

          //this.paymentDetails.isOrderByGuideAgency = 2
        }
      }).catch((e) => {

      }).finally(() => {
        this.searchingBusinessCode = false
      })
    },
    searchBusinessCode() {
      if (this.paymentDetails.agencyGuideBusinessCode.trim().length <= 0) {
        alert('Código inválido')
        return false
      }
      this.paymentDetails.guideAgencyFound = null
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.searchingBusinessCode = true
      this.$http.post(`${this.$apiUrl}/premier-customers/search-business-code`, {
        business_code: this.paymentDetails.agencyGuideBusinessCode
      }).then((res) => {
        if (res.data.success) {
          this.paymentDetails.guideAgencyFound = res.data.data
          this.showBusinessCodeResultError = false
          this.showBusinessCodeResultSuccess = true
        } else {
          this.showBusinessCodeResultError = true
          this.showBusinessCodeResultSuccess = false
          this.paymentDetails.agencyGuideBusinessCode = ''

          this.paymentDetails.isOrderByGuideAgency = 2
        }
      }).catch((e) => {

      }).finally(() => {
        this.searchingBusinessCode = false
      })
    },
    confirmOrderShoppingCart() {

      let getAmountCommissionLiquors = this.getAmountCommissionLiquors()
      let getAmountCommissionMerchant = this.getAmountCommissionMerchant()
      let getAmountsMerchantLiquors = this.getAmountsMerchantLiquors()

      let amountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          subAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction),
          taxesAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction),
          amountTransaction = (this.cartAmounts.totalTwoAmount),
          subAmountTransaction = (this.cartAmounts.totalOneAmount),
          taxesAmountTransaction = (this.cartAmounts.amountTaxes),
          discountsAppliedMxn = this.cartAmounts.discountsAppliedMxn,
          amountCommissionLiquors = getAmountCommissionLiquors.amount_commission,
          amountCommissionMerchant = getAmountCommissionMerchant.amount_commission,
          finalAmountCommission = (getAmountCommissionLiquors.amount_commission + getAmountCommissionMerchant.amount_commission),
          amountItemsMerchant = getAmountsMerchantLiquors.amount_merchant,
          amountItemsLiquors = getAmountsMerchantLiquors.amount_liquors

      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = true
      this.invoiceDetailsCreated = null

      //console.log('amountTransaction-->' + amountTransaction)
      //console.log(this.itemsCart)
      //console.log(this.paymentDetails)

      this.loadingOrderCreation = true
      this.$http.post(`${this.$apiUrl}/cart-payments/confirm-create-order`, {
        order_opened_comments: this.orderShoppingCart.additionalComments,
        items_cart: this.itemsCart,
        payment_details: this.paymentDetails,
        amountTransactionConverted: amountTransactionConverted,
        subAmountTransactionConverted: subAmountTransactionConverted,
        taxesAmountTransactionConverted: taxesAmountTransactionConverted,
        amountTransaction: amountTransaction,
        subAmountTransaction: subAmountTransaction,
        taxesAmountTransaction: taxesAmountTransaction,
        discountsAppliedMxn: discountsAppliedMxn,
        amountItemsMerchant: amountItemsMerchant,
        amountItemsLiquors: amountItemsLiquors,
        amountCommissionLiquors: amountCommissionLiquors,
        amountCommissionMerchant: amountCommissionMerchant,
        finalAmountCommission: finalAmountCommission
      }).then((res) => {
        if (res.data.success) {

          // clean cart
          this.cleanShoppingCart()

          this.orderOpenedCreated = true
          this.orderFinishedVoided = false
          this.invoiceDetailsCreated = res.data.data
          // close modal
          this.ordenCompraDialog = false
        }
      }).finally(() => {
        this.loadingPaymentCart = false

      })
    },

    validateGuideForm() {

    },

    confirmAndFinishOrder() {


      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = true
      this.invoiceDetailsCreated = null
      let getAmountCommissionLiquors = this.getAmountCommissionLiquors()
      let getAmountCommissionMerchant = this.getAmountCommissionMerchant()
      let getAmountsMerchantLiquors = this.getAmountsMerchantLiquors()

      let amountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          subAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction),
          taxesAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction),
          amountTransaction = (this.cartAmounts.totalTwoAmount),
          subAmountTransaction = (this.cartAmounts.totalOneAmount),
          taxesAmountTransaction = (this.cartAmounts.amountTaxes),
          discountsAppliedMxn = this.cartAmounts.discountsAppliedMxn,
          discountsAmountMoneyUsd = this.convertAmountToCurrency(this.cartAmounts.discountsAppliedMxn, this.currenciesChanges[0]),
          discountsAmountMoneyEur = this.convertAmountToCurrency(this.cartAmounts.discountsAppliedMxn, this.currenciesChanges[1]),
          amountCommissionLiquors = getAmountCommissionLiquors.amount_commission,
          amountCommissionMerchant = getAmountCommissionMerchant.amount_commission,
          finalAmountCommission = (getAmountCommissionLiquors.amount_commission + getAmountCommissionMerchant.amount_commission),
          finalPercentageCommissionMerchant = (this.paymentDetails.guideAgencyFound) ? this.paymentDetails.guideAgencyFound.guide.finalCommissionMerchant : 0,
          finalPercentageCommissionLiquors = (this.paymentDetails.guideAgencyFound) ? this.paymentDetails.guideAgencyFound.guide.finalCommissionLiquors : 0,
          amountItemsMerchant = getAmountsMerchantLiquors.amount_merchant,
          amountItemsLiquors = getAmountsMerchantLiquors.amount_liquors,
          subamountMerchantUsd = this.convertAmountToCurrency(getAmountsMerchantLiquors.amount_merchant, this.currenciesChanges[0]),
          subamountLiquorsUsd = this.convertAmountToCurrency(getAmountsMerchantLiquors.amount_liquors, this.currenciesChanges[0]),
          subamountMerchantEur = this.convertAmountToCurrency(getAmountsMerchantLiquors.amount_merchant, this.currenciesChanges[1]),
          subamountLiquorsEur = this.convertAmountToCurrency(getAmountsMerchantLiquors.amount_liquors, this.currenciesChanges[1])

      this.$http.post(`${this.$apiUrl}/cart-payments/confirm-finish-order`, {
        invoiceOrderNumberFound: this.invoiceOrderNumberFound,
        invoiceIdOpenedFound: this.invoiceIdOpenedFound,
        items_cart: this.itemsCart,

        payment_details: this.paymentDetails,
        amountTransactionConverted: amountTransactionConverted,
        subAmountTransactionConverted: subAmountTransactionConverted,
        taxesAmountTransactionConverted: taxesAmountTransactionConverted,
        amountTransaction: amountTransaction,
        subAmountTransaction: subAmountTransaction,
        taxesAmountTransaction: taxesAmountTransaction,
        discountsAppliedMxn: discountsAppliedMxn,
        discountsAmountMoneyUsd: discountsAmountMoneyUsd,
        discountsAmountMoneyEur: discountsAmountMoneyEur,
        amountItemsMerchant: amountItemsMerchant,
        amountItemsLiquors: amountItemsLiquors,
        amountCommissionLiquors: amountCommissionLiquors,
        amountCommissionMerchant: amountCommissionMerchant,
        finalPercentageCommissionMerchant: finalPercentageCommissionMerchant,
        finalPercentageCommissionLiquors: finalPercentageCommissionLiquors,
        finalAmountCommission: finalAmountCommission,
        subamountMerchantUsd: subamountMerchantUsd,
        subamountLiquorsUsd: subamountLiquorsUsd,
        subamountMerchantEur: subamountMerchantEur,
        subamountLiquorsEur: subamountLiquorsEur,
      }).then((res) => {
        if (res.data.success) {

          // clean cart
          this.cleanShoppingCart()

          this.cartSettings.applyDiscountToOrder = false
          this.cartAmounts.discountsAppliedMxn = 0
          this.orderOpenedCreated = false
          this.orderFinishedVoided = true

          this.invoiceDetailsCreated = res.data.data

          this.printInvoiceOrderFinished()
          this.getLastFinishedOrders()
          this.getLastOpenedOrders()

        }
      }).finally(() => {
        this.loadingPaymentCart = false

      })

    },
    async printInvoiceOrderFinished() {
      let pdfInvoice = this.getOrderTicketPdfUrl()
      const result = await this.$printPDF(pdfInvoice);
      if (result.success) {
        console.log('PDF enviado correctamente a la impresora.');
      } else {
        console.error('Error al imprimir PDF:', result.error);
      }
    },
    calculateRemainsAmount(index) {
      let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
      }

      let amountRequiredToPay = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction)

      if (totalAmountSpecified > amountRequiredToPay) {
        this.paymentDetails.payments[index]['paymentAmount'] = 0
        return false
      }

      this.paymentDetails.payments[index]['paymentAmount'] = (parseFloat(amountRequiredToPay) - parseFloat(totalAmountSpecified)).toFixed(2)
      this.saveAmountsConvertedPaymentAmount(this.paymentDetails.payments[index], index)
    },
    getTotalAmountSpecified() {
      let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        //cash
        if (this.paymentDetails.payments[pm].paymentMethod === 1) {
          totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].cashAmountProvided)
        }
        //other
        else {
          totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
        }

      }
      return totalAmountSpecified
    },
    canProceedPaymentConfirmation() {
      /*let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
      }*/
      let amountRequiredToPay = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          totalAmountSpecified = this.getTotalAmountSpecified()
      console.log(totalAmountSpecified, amountRequiredToPay)

      if (this.paymentDetails.orderComments.trim().length > 249 || this.paymentDetails.orderComments.trim().length <= 0) {
        return false
      }

      return (totalAmountSpecified >= parseFloat(amountRequiredToPay))
    },
    getAmountWithConversion(finalAmount, currentPaymentCurrency) {
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return finalAmount
      }
      console.log(currentPaymentCurrency.change_currency.current_amount_mxn)

      return parseFloat(finalAmount / currentPaymentCurrency.change_currency.current_amount_mxn).toFixed(2)
    },
    getAmountConversionToMXN(finalAmount, currentPaymentCurrency) {
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return parseFloat(finalAmount).toFixed(2)
      }
      return parseFloat(parseFloat(finalAmount) * currentPaymentCurrency.change_currency.current_amount_mxn).toFixed(2)
    },
    changeNumberPayments(){
      this.proceedPaymentDetailsForm = false
    },
    confirmNumberPayments() {

      this.getGuidesAgenciesVisits()
      // if guide section is filles but not submitted we alert just in case
      if (this.paymentDetails.agencyGuideBusinessCode.trim().length > 0 || this.paymentDetails.isOrderByGuideAgency === 1) {
        // guide was not validated yet
        if (!this.paymentDetails.guideAgencyFound) {
          let confirmContinue = confirm('Hemos detectado que ingresaste un número de guía pero no se ha validado, ¿deseas continuar sin enlazar esta compra a un guía?')
          if (confirmContinue) {
            this.paymentDetails.agencyGuideBusinessCode = ''
            this.paymentDetails.guideAgencyFound = null
            this.paymentDetails.isOrderByGuideAgency = 2
          } else {
            return false
          }
        }
      }


      if (this.proceedPaymentDetailsForm) {
        let confirmChangingPayments = confirm('¿Confirma modificar los pagos? Tendrá que capturarlos de nuevo')
        if (!confirmChangingPayments) {
          return false
        }
      }

      this.paymentDetails.cashCaptured = true
      this.proceedPaymentDetailsForm = true
      this.paymentDetails.payments = []
      for (let pm = 0; pm < this.paymentDetails.numberPayments; pm++) {
        // first payment is always in cash, others are different than cash
        this.paymentDetails.payments.push({
          paymentAmount: 0,
          paymentMethod: (pm !== 0) ? 2 : 1,
          cashAmountProvided: 0,
          changeCalculated: 0,
          changeCalculatedMxn: 0,
          tpvType: null,
        })
      }

      // default calculated amount
      // only applies if number of payments is equal to 1
      if (this.paymentDetails.numberPayments === 1) {
        this.paymentDetails.payments[0]['paymentAmount'] = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction)
      }

      /*// discounting
      if(oldVal > val) {
        let confirmDelete = confirm('Hemos detectado que intentas eliminar un pago, da click en confirmar si es así o da cancelar para no ejecutar ninguna acción')
        if(confirmDelete) {
          this.paymentDetails.payments.splice(-1)
        }
      }
      // adding
      else {
        this.paymentDetails.payments.push({
          paymentAmount: 0,
          paymentMethod: 1,
        })
      }
      console.log(val, oldVal)*/

      this.updateItemsPricesConverted()
    },
    openOrdenCompraDialog() {
      this.ordenCompraDialog = true
    },
    openFinalizarCompraDialog() {

      // Get visitors who are inside the store of today
      this.getCurrentVisits()

      // MEANS NO
      this.paymentDetails.isOrderByGuideAgency = 2
      this.paymentDetails.guideAgencyFound = null
      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = false
      this.invoiceDetailsCreated = null

      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.showHideOrderResume = false
      this.finalizarCompraDialog = true
      this.proceedPaymentDetailsForm = false

      //this.paymentDetails.cashAmountProvided = 0
      //this.paymentDetails.changeCalculated = 0
      this.paymentDetails.numberPayments = 1

      // default currency
      this.paymentDetails.currencyTransaction = this.activeCurrencies[0]

      this.updateItemsPricesConverted()

    },
    updateItemsPricesConverted() {
      // updating prices of items in the currency selectedd
      this.itemsCart.forEach((item, i) => {
        let priceItemConverted = this.getAmountWithConversion(item.concept_price_mxn, this.paymentDetails.currencyTransaction),
            itemQuantitie = parseInt(item.quantities)

        item['concept_price_converted_unit'] = priceItemConverted
        item['concept_price_converted_total'] = priceItemConverted * itemQuantitie
      })
    },
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    getCurrenciesChanges() {
      this.$http.get(`${this.$apiUrl}/changes-currencies/get-active-changes-currencies`)
          .then((res) => {
            this.currenciesChanges = res.data.data
          }).catch((e) => {

      })
    },
    getActiveCurrencies() {
      this.$http.get(`${this.$apiUrl}/currencies/get-active-currencies`)
          .then((res) => {
            this.activeCurrencies = res.data.data
          }).catch((e) => {

      })
    },
    storeChangeCurrency(currencyId) {
      let amountCurrency = 0
      // USD
      if (currencyId === 2) {
        amountCurrency = this.changesCurrencyUSD.amount
        if (this.changesCurrencyUSD.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }
      // MXN
      else if (currencyId === 1) {
        amountCurrency = this.changesCurrencyMXN.amount
        if (this.changesCurrencyMXN.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }
      // EUR
      else {
        amountCurrency = this.changesCurrencyEUR.amount
        if (this.changesCurrencyEUR.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }

      let confirmSave = confirm('¿Está seguro de guardar? Este nuevo valor se activará y el valor pasado quedará inactivo')
      if (confirmSave) {
        this.$http.post(`${this.$apiUrl}/changes-currencies`, {
          currency_id: currencyId,
          current_amount_mxn: amountCurrency,
        }).then((res) => {
          console.log(res.data)
        }).finally(() => {
          this.getHistoryCurrChanges()
          this.getCurrenciesChanges()
        })
      }
    },
    getHistoryCurrChanges() {
      this.$http.get(`${this.$apiUrl}/changes-currencies`)
          .then((res) => {
            this.historyCurrencyChanges = res.data.data
          })
    },
    calculateAmounts() {
      let localTotalAmount = 0,
          amountTaxes = 0


      this.cartAmounts.amountTaxes = 0
      this.cartAmounts.totalOneAmount = 0
      this.cartAmounts.totalTwoAmount = 0
      if (this.itemsCart && this.itemsCart.length > 0) {
        this.itemsCart.forEach((item, i) => {
          localTotalAmount = localTotalAmount + parseFloat(item.amount_item)
        })

        /*
        // calculate taxes if applies
        if (localTotalAmount > 0 && this.activeIVATax) {
          amountTaxes = parseFloat(localTotalAmount * (this.cartAmounts.ivaTaxPercentage / 100)).toFixed(2)

          this.cartAmounts.amountTaxes = amountTaxes
        }*/

        this.cartAmounts.totalOneAmount = parseFloat(localTotalAmount).toFixed(2)
        // Applying discounts
        if (this.cartAmounts.discountsAppliedMxn && this.cartAmounts.discountsAppliedMxn > 0) {
          localTotalAmount = localTotalAmount - this.cartAmounts.discountsAppliedMxn
        }
        //  this.cartAmounts.totalTwoAmount = (parseFloat(localTotalAmount) + parseFloat(amountTaxes)).toFixed(2)
        this.cartAmounts.totalTwoAmount = parseFloat(localTotalAmount).toFixed(2)
      }
    },
    updateAmountItemCart(itemCart, i) {
      itemCart['amount_item'] = parseFloat((parseInt(itemCart.quantities) * parseFloat(itemCart.concept_price_mxn))).toFixed(2)

      this.calculateAmounts()
    },
    deleteItemCart(itemCart) {
      // Confirmación antes de eliminar
      let deleteConfirm = confirm('¿Quiere eliminar este artículo?');

      if (deleteConfirm) {
        // Buscar el índice del elemento en itemsCart basado en el concept_id, asegurando que ambos sean del mismo tipo
        const index = this.itemsCart.findIndex(cartItem =>
            parseInt(cartItem.concept_id) === parseInt(itemCart.concept_id) // Asegurarse de que concept_id sea del mismo tipo
        );

        // Si el índice es válido, eliminar el elemento
        if (index !== -1) {
          this.itemsCart.splice(index, 1);  // Eliminar el elemento del carrito
          this.calculateAmounts();  // Actualizar los montos después de eliminar el elemento
          console.log('Artículo eliminado:', itemCart.concept_name);
        } else {
          console.warn('El artículo no fue encontrado en el carrito.');
        }
      }
    },
    searchItemsConceptsCart(itemToSearch) {
      console.log(itemToSearch)

    },
    addItemToCart() {
      this.dialogAddItemCart = true
    },
    getMerchantConcepts() {
      this.$http.get(`${this.$apiUrl}/merchants`)
          .then((res) => {
            this.merchantList = res.data.data
            this.financialConceptForm.merchantId = this.merchantList[0].id
          })
    },
    getTpvTypesList() {
      this.tpvTypesList = []
      this.$http.get(`${this.$apiUrl}/tpvtypes`)
          .then((res) => {
            this.tpvTypesList = res.data.data
          })
    },
    storeFinancialConcept() {

      if (
          this.financialConceptForm.conceptName.trim().length === 0 || // Validar que el nombre no esté vacío
          this.financialConceptForm.conceptDetails.trim().length === 0 || // Validar que los detalles no estén vacíos
          parseFloat(this.financialConceptForm.conceptPriceMxn) <= 0 || // Validar que el precio sea mayor a 0
          parseInt(this.financialConceptForm.merchantId) <= 0 // Validar que el merchantId sea mayor a 0
      ) {
        console.log('this.financialConceptForm.conceptName = ', this.financialConceptForm.conceptName);
        console.log('this.financialConceptForm.conceptDetails = ', this.financialConceptForm.conceptDetails);
        console.log('this.financialConceptForm.conceptPriceMxn = ', this.financialConceptForm.conceptPriceMxn);
        console.log('this.financialConceptForm.merchantId = ', this.financialConceptForm.merchantId);

        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'Hay campos faltantes o incorrectos. Revisa los datos e inténtalo nuevamente.',
        });

        return false;
      }


      this.showErrorMessage = false
      this.showSavedMessage = false
      // store
      this.loadingStoreConcept = true

      this.$http.post(`${this.$apiUrl}/financial-concepts`, {
        concept_name: this.financialConceptForm.conceptName,
        concept_details: this.financialConceptForm.conceptDetails,
        concept_price_mxn: this.financialConceptForm.conceptPriceMxn,
        merchant_id: this.financialConceptForm.merchantId
      }).then((res) => {
        this.showSavedMessage = true
        this.financialConceptForm.conceptName = ''
        this.financialConceptForm.conceptDetails = 'N/A'
        this.financialConceptForm.conceptPriceMxn = 0
        this.getFinancialConceptsHistory()
      }).catch((e) => {
        this.showSavedMessage = false
        this.showErrorMessage = true
      }).finally(() => {
        this.loadingStoreConcept = false

      })
    }
  }
}
</script>
<style>


</style>