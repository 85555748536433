import axios from 'axios';
import Vue from 'vue';

// Leer las variables de entorno para los endpoints
const REMOTE_API_URL = process.env.VUE_APP_REMOTE_API_URL || 'https://api.premierartesanias.com/api';
const LOCAL_API_URL = process.env.VUE_APP_LOCAL_API_URL || 'http://localhost:8000/api';
const REMOTE_FRONTEND_URL = process.env.VUE_APP_REMOTE_FRONTEND_URL || 'https://premierartesanias.com';
const LOCAL_FRONTEND_URL = process.env.VUE_APP_LOCAL_FRONTEND_URL || 'http://localhost:8081';

// Estado inicial
let manualMode = localStorage.getItem('manualMode') || null; // Recuperar modo manual desde localStorage

// Función para determinar el endpoint del backend según el estado actual
function getApiUrl() {
    return manualMode === 'online' ? REMOTE_API_URL : LOCAL_API_URL;
}

// Función para determinar la URL del frontend según el estado actual
function getFrontendUrl() {
    return manualMode === 'online' ? REMOTE_FRONTEND_URL : LOCAL_FRONTEND_URL;
}

// Configurar Axios dinámicamente con el endpoint actual
const http = axios.create({
    baseURL: getApiUrl(),
    headers: { 'Content-Type': 'application/json' },
});

// Cambiar el modo manualmente (offline o online)
function setManualMode(mode) {
    if (mode !== 'online' && mode !== 'offline') {
        console.error("Modo inválido proporcionado:", mode);
        return;
    }

    manualMode = mode;
    localStorage.setItem('manualMode', mode);

    const apiUrl = getApiUrl();
    const frontendUrl = getFrontendUrl();

    Vue.prototype.$apiUrl = apiUrl;
    Vue.prototype.$frontendUrl = frontendUrl;
    http.defaults.baseURL = apiUrl;

    console.log(`Modo manual actualizado a: ${mode}`);
    console.log(`API URL actualizada a: ${apiUrl}`);
    console.log(`Frontend URL actualizada a: ${frontendUrl}`);

    // Sincronizar con Electron
    if (window.electronAPI) {
        window.electronAPI
            .setConfig(frontendUrl, apiUrl, mode)
            .then(() => {
                console.log("URLs y modo actualizados en Electron:", {
                    frontendUrl,
                    backendUrl: apiUrl,
                    mode,
                });
                // Reiniciar la app para aplicar cambios
                console.log('Aqui deberia de reiniciarse y guardar los valores nuevos')
                //window.electronAPI.restartApp();
            })
            .catch((error) => {
                console.error("Error al actualizar la configuración en Electron:", error.message);
            });
    }
}

// Obtener el estado actual de conexión y configuración
function getConnectionState() {
    return {
        manualMode,
        currentApiUrl: http.defaults.baseURL,
        currentFrontendUrl: Vue.prototype.$frontendUrl,
    };
}

// Sincronizar URLs desde Electron al iniciar
function initializeUrls() {
    if (window.electronAPI && typeof window.electronAPI.getConfig === 'function') {
        window.electronAPI
            .getConfig()
            .then((config) => {
                manualMode = config.mode || 'online';
                const apiUrl = config.backendUrl || getApiUrl();
                const frontendUrl = config.frontendUrl || getFrontendUrl();

                Vue.prototype.$apiUrl = apiUrl;
                Vue.prototype.$frontendUrl = frontendUrl;
                http.defaults.baseURL = apiUrl;

                console.log("URLs iniciales sincronizadas desde Electron:", {
                    apiUrl,
                    frontendUrl,
                    mode: manualMode,
                });
            })
            .catch((error) => {
                console.error("Error al sincronizar URLs iniciales desde Electron:", error.message);
            });
    } else {
        console.warn("Electron no está disponible, usando URLs locales.");
        const apiUrl = getApiUrl();
        const frontendUrl = getFrontendUrl();

        Vue.prototype.$apiUrl = apiUrl;
        Vue.prototype.$frontendUrl = frontendUrl;
        http.defaults.baseURL = apiUrl;
    }
}

initializeUrls();

export default {
    http,
    setManualMode,
    getConnectionState,
};
