<template>
  <div class="">
    <v-app-bar
        color="light"
    >
      <v-app-bar-nav-icon class="" @click="drawer = true"></v-app-bar-nav-icon>

      <v-img class="mx-2" max-width="150" src="/small-icon.png"></v-img>

      <v-toolbar-title class="light-blue--text text--darken-4">
        Inicio
      </v-toolbar-title>


    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="openLinkMenu('/')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title  >Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/customers')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Clientes</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/reports/report-sales')">
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Reporte de global de caja</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/reports/report-payments-sales')">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Reportes de pagos de caja</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/reports/report-commissions')">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Reportes de comisiones</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/colors')">
            <v-list-item-icon>
              <v-icon>mdi-dots-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Administrar colores</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openLinkMenu('/testing/testing-page')">
            <v-list-item-icon>
              <v-icon>mdi-heart-settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Testing</v-list-item-title>
          </v-list-item>



        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: 'LeftMenu',
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods:{
    openLinkMenu(link) {
      this.$router.push(link)
    }
  }
}
</script>
