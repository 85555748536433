<template>
  <div>
    <v-simple-table>
      <tbody>
      <tr>
        <td>Fecha y hora de visita:</td>
        <td>{{ moment(detailsGuideVisitSales.created_at).format('D [de] MMMM [de] YYYY') }}</td>
      </tr>
      <tr>
        <td>Agencia:</td>
        <td>{{ detailsGuideVisitSales.guide.guide_name }}</td>
      </tr>
      <tr>
        <td>Guía:</td>
        <td>{{ detailsGuideVisitSales.guide.agency.agency_business_name }}</td>
      </tr>
      <tr>
        <td>Número de ventas totales de la visita:</td>
        <td>{{ detailsGuideVisitSales.invoice_register_visit.length.length }}</td>
      </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table>
      <thead>
      <tr>
        <th># Folio</th>
        <th>Monto total mercancía</th>
        <th>Monto total licores</th>
        <th>Monto total venta</th>
        <th>Comisión mercancía</th>
        <th>Comisión licores</th>
        <th>Comisión total</th>
      </tr>
      </thead>
      <tbody>
      <template
          v-if="detailsGuideVisitSales.invoice_register_visit && detailsGuideVisitSales.invoice_register_visit.length > 0">
        <tr v-for="itemInvoice in detailsGuideVisitSales.invoice_register_visit">
          <td>
            {{ itemInvoice.invoice_string_id }}
          </td>
          <td>
            {{ itemInvoice.subamount_merchant_original }}
          </td>
          <td>
            {{ itemInvoice.subamount_liquors_original }}
          </td>
          <td>
            {{ itemInvoice.amount_converted }}
          </td>
          <td>
            {{ itemInvoice.guide_comission_merchant }}
            <p class="ma-0 pa-0 black--text font-weight-bold">
              <small>{{ itemInvoice.final_percentage_commission_merchant_guide }}%</small></p>
          </td>
          <td>
            {{ itemInvoice.guide_comission_liquors }}
            <p class="ma-0 pa-0 black--text font-weight-bold">
              <small>{{ itemInvoice.final_percentage_commission_liquors_guide }}%</small></p>
          </td>
          <td>
            <span class="font-weight-bold black--text">
              {{ itemInvoice.guide_commission_amount }}
            </span>
          </td>
        </tr>

        <tr>
          <td colspan="6" class="text-right">
            <p class="ma-0 pa-0">Total final de comisión por ventas:</p>
            <p class="ma-0 pa-0"><small><v-icon>mdi-information-outline</v-icon> Esta cantidad se sumará al gran total final</small></p>
          </td>
          <td>
            <p class="text-left ma-0 pa-0 font-weight-bold">
              ${{ number_format(detailsGuideVisitSales.total_amount_commissions_mxn, 2, '.', ',') }}</p>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-right">
            <p class="ma-0 pa-0">Total de ventas generadas:</p>
            <p class="ma-0 pa-0"><small><v-icon>mdi-information-outline</v-icon> Dato meramente informativo</small></p>
          </td>
          <td>
            <p class="text-left ma-0 pa-0 font-weight-bold">
              ${{ number_format(detailsGuideVisitSales.total_amount_sales_mxn, 2, '.', ',') }}</p>
          </td>
        </tr>
        <tr>
          <td colspan="7" class="text-right font-weight-bold black--text">
            {{ numberToLettersMxn(detailsGuideVisitSales.total_global_amount_commission_mxn) }}
          </td>
        </tr>
      </template>
      <template v-else></template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import NumeroALetras from "@/utils/numeroALetras";

export default {
  name: 'DetailsGuidePayVisit',
  props: ['detailsGuideVisitSales'],
  methods: {
    numberToLettersMxn(numberToConvert = 0) {
      if (!numberToConvert || numberToConvert <= 0) {
        return ''
      }
      return NumeroALetras(numberToConvert);
    },
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
  },
}
</script>

<style scoped>
</style>