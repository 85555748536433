<template>
  <div>
    <h2 class="pt-2 pb-2 black--text mt-5">Resumen del cálculo de pago de comisiones del guía</h2>
    <v-simple-table>
      <tbody>

      <tr>
        <td>Fecha</td>
        <td class="font-weight-regular">
          {{ moment(invoiceRegisterDate).format('DD-MM-YYYY') }}
        </td>
      </tr>
      <tr>
        <td>Llegada No.</td>
        <td class="font-weight-regular">
          {{ currentRegisterVisitPay.arrival_order_number }}
        </td>
      </tr>
      <tr>
        <td>Guía</td>
        <td class="font-weight-regular">
          {{ currentRegisterVisitPay.guide.guide_name }}
          <strong>({{ currentRegisterVisitPay.guide.unique_code_business }})</strong>
        </td>
      </tr>
      <tr>
        <td>Agencia</td>
        <td class="font-weight-regular">
          {{ currentRegisterVisitPay.guide.agency.agency_business_name }}
        </td>
      </tr>
      <tr>
        <td>Vendedores asignados</td>
        <td class="font-weight-regular">
          -
        </td>
      </tr>
      <tr>
        <td>Venta total</td>
        <td class="font-weight-regular">
          {{ number_format(currentRegisterVisitPay.total_amount_sales_mxn, 2, '.', ',') }}
        </td>
      </tr>
      <tr>
        <td>Comisión</td>
        <td class="font-weight-regular">
          {{ number_format(currentRegisterVisitPay.total_amount_commissions_mxn, 2, '.', ',') }}
        </td>
      </tr>
      <tr>
        <td>Visita</td>
        <td class="font-weight-regular">
          {{ number_format(currentRegisterVisitPay.guide.guide_commission_visit_amount, 2, '.', ',') }}
        </td>
      </tr>
      <template v-if="parseInt(currentRegisterVisitPay.guide_come_with_driver) === 1">
        <tr>
          <td>Número de operadores/Chóferes</td>
          <td class="font-weight-regular">
            {{
              currentRegisterVisitPay.sales_users_register_visit.length
            }}
          </td>
        </tr>
        <tr>
          <td>Comisión por Operadores/Chóferes</td>
          <td class="font-weight-regular">
            {{
              number_format((currentRegisterVisitPay.guide.guide_commission_visit_amount_chofer*currentRegisterVisitPay.sales_users_register_visit.length), 2, '.', ',')
            }}
          </td>
        </tr>
      </template>
      <tr>
        <td colspan="2">
          <p class="mt-2 ma-0 pa-0">Gran total</p>
          <p class="ma-0 pa-0 text-h5">
            ${{ number_format(currentRegisterVisitPay.total_global_amount_commission_mxn, 2, '.', ',') }}</p>
          <p style="text-transform: capitalize;" class="ma-0 pa-0 font-weight-bold">
            {{ numberToLettersMxn(currentRegisterVisitPay.total_global_amount_commission_mxn) }}</p>

        </td>
        <td>

        </td>
      </tr>


      </tbody>
    </v-simple-table>

    <!--              <v-simple-table>
                    <thead>
                    <tr>
                      <th>Guía</th>
                      <th>Código</th>
                      <th>Agencia</th>
                      <th>Comisión Chófer</th>
                      <th>Total comisión</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{{ (currentRegisterVisitPay.guide.guide_name) }}</td>
                      <td>{{ (currentRegisterVisitPay.guide.unique_code_business) }}</td>
                      <td>{{ (currentRegisterVisitPay.guide.guide_name) }}</td>
                      <td>{{ (parseInt(currentRegisterVisitPay.guide_come_with_driver) !== 0 ? `${(100).toFixed(2)}` : 'No') }}</td>
                      <td>${{ getCalculateFinalVisitCommission(currentRegisterVisitPay) }}</td>
                    </tr>

                    <tr>
                      <td colspan="5"><small>* Los montos son expresados en pesos mexicanos y puede cambiar sin previo
                        aviso</small></td>
                    </tr>
                    <tr>
                      <td colspan="5"><small>* Algunos datos en esta tabla podrían ocultarse por motivos de privacidad y se
                        requieren permisos adicionales para mostrarlos</small></td>
                    </tr>

                    </tbody>

                  </v-simple-table>-->
  </div>
</template>

<script>
import NumeroALetras from "@/utils/numeroALetras";

export default {
  name: 'ResumeGuidePay',
  props: ['invoiceRegisterDate', 'currentRegisterVisitPay'],
  methods: {
    numberToLettersMxn(numberToConvert = 0) {
      if (!numberToConvert || numberToConvert <= 0) {
        return ''
      }
      return NumeroALetras(numberToConvert);
    },
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
  },
}
</script>

<style scoped>
</style>