import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,[_c('p',{staticClass:"display-3 ma-0 pa-0 mb-5"},[_vm._v("Testing")]),_c('hr')]),_c(VCardSubtitle,[_c('p',[_vm._v("Módulo para testing")])]),_c(VCardText,[_c('h2',[_vm._v("Modo de conexión")]),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.changeMode('online')}}},[_vm._v("Cambiar a Online")]),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.changeMode('offline')}}},[_vm._v("Cambiar a Offline")]),_c('p',[_vm._v("Estado actual de la API (Laravel): "+_vm._s(_vm.connectionState.currentApiUrl))]),_c('p',[_vm._v("Estado actual del frontend (Vue): "+_vm._s(_vm.connectionState.currentFrontendUrl))]),_c('h2',{staticClass:"mt-5"},[_vm._v("Impresiones")]),_c(VTextField,{attrs:{"label":"Invoice URL"},model:{value:(_vm.pdfUrl),callback:function ($$v) {_vm.pdfUrl=$$v},expression:"pdfUrl"}}),_c('p',[_vm._v("Preview:")]),_c('iframe',{staticStyle:{"width":"500px","height":"500px"},attrs:{"src":_vm.pdfUrl}}),_c('br'),_c(VBtn,{staticClass:"white--text",attrs:{"color":"black","x-large":""},on:{"click":_vm.testPrint}},[_vm._v("Print now")]),_c('br'),_c(VBtn,{attrs:{"color":"blue","x-large":""},on:{"click":_vm.getPrinters}},[_vm._v("Get printers")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }